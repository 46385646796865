import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import Avatar from "@mui/material/Avatar";
import {getInitials, stringToColor} from "../../../utils/helperFunction";
import useNetworkHandling from "../../../hooks/useNetworkHandling";
import {APIS} from "../../../utils/constants";
import PatientSummarySkeleton from "./PatientSummarySkeleton";

interface Props {
  patientId: string | null;
  showDialog: boolean;
  handleClose: () => void;
}

const tabs = [
  {
    label: 'Abstracted Data',
    name: 'AbstractedData',
  },
  {
    label: 'Consent',
    name: 'Consent',
  },
];

const PatientSummary: React.FC<Props> = ({patientId, showDialog, handleClose}) => {
  const theme = useTheme();
  const {getData} = useNetworkHandling();
  const [activeTab, setActiveTab] = useState<string>('AbstractedData');
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const tabDetails = () => {
    switch (activeTab) {
      case 'AbstractedData':
        return 'AbstractedData';

      case 'Consent':
        return 'Consent';
    }
  }

  const getDiagnosis = async (id: string) => {
    try {
      setShowLoader(true);
      const {data} = await getData(`${APIS.PATIENTS}/${id}/diagnosis`);
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (patientId) {
      getDiagnosis(patientId).then(r => {
      });
    }
  }, [patientId]);

  return (
    <>
      <Dialog open={showDialog} fullWidth maxWidth={'lg'}>
        {showLoader ? (
          <PatientSummarySkeleton/>
        ) : (
          <>
            <Box display={'flex'} justifyContent={'space-between'} pl={4} pr={2}
                 sx={{backgroundColor: theme.palette.secondary.main}}>
              <Box display={'flex'} alignItems={'flex-end'}>
                <Box pt={1.5} pb={1.5}>
                  <Typography variant={'h1'} color={'#fff'}>
                    Patient MRN: {patientId}
                  </Typography>
                </Box>
                <Box display={'flex'} ml={3} alignItems={'flex-end'}>
                  {tabs.map(tab => {
                    const isActive = tab.name === activeTab;
                    if (isActive) {
                      return (
                        <Box key={tab.name} pt={1.5} pl={1} pr={1} pb={1.5} mr={0.5}
                             sx={{
                               backgroundColor: theme.palette.brandColorPrimary.main,
                               borderTopLeftRadius: 6,
                               borderTopRightRadius: 6
                             }}>
                          <Typography variant={'h4'} color={theme.palette.hyperLink.main}>
                            {tab.label}
                          </Typography>
                        </Box>
                      );
                    }
                    return (
                      <Box onClick={() => setActiveTab(tab.name)} key={tab.name} pt={1} pl={1} pr={1} pb={1} mr={0.5}
                           sx={{
                             backgroundColor: theme.palette.accentListTable.main,
                             borderTopLeftRadius: 6,
                             borderTopRightRadius: 6
                           }}>
                        <Typography variant={'h6'} color={theme.palette.blackBold.main}>
                          {tab.label}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <IconButton aria-label="cancel" color="primary" onClick={handleClose}>
                <ClearIcon/>
              </IconButton>
            </Box>
            <Box pl={4} pt={4} pr={2}>
              <Box mb={2.5} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography variant={'h4'} color={theme.palette.blackBold.main}>
                    Name:&nbsp;
                  </Typography>
                  <Typography variant={'h6'} color={theme.palette.blackBold.main}>
                    Doe, Jane
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography variant={'h4'} color={theme.palette.blackBold.main}>
                    Sex:&nbsp;
                  </Typography>
                  <Typography variant={'h6'} color={theme.palette.blackBold.main}>
                    F
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography variant={'h4'} color={theme.palette.blackBold.main}>
                    DOB:&nbsp;
                  </Typography>
                  <Typography variant={'h6'} color={theme.palette.blackBold.main}>
                    01/08/1938 (85)
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography variant={'h4'} color={theme.palette.blackBold.main}>
                    Status:&nbsp;
                  </Typography>
                  <Avatar alt={'Accepted'}
                          sx={{bgcolor: stringToColor('Accepted'), width: 30, height: 30, marginRight: 1}}>
                    {getInitials('Accepted')}
                  </Avatar>
                  <Avatar alt={'Consented'}
                          sx={{bgcolor: stringToColor('Consented'), width: 30, height: 30, marginRight: 1}}>
                    {getInitials('Consented')}
                  </Avatar>
                </Box>
              </Box>
              {tabDetails()}
            </Box>
          </>
        )}
      </Dialog>
    </>
  );
};

export default PatientSummary;
