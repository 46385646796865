import axios from "axios";
import {useAppContext} from "../context/AppContext";
import {REACT_APP_SERVER_URL} from "../utils/constants";

export default () => {
  const {bearerToken} = useAppContext();

  const getOptionsWithToken = () => {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
    };
  };

  const getData = async (url: string) => {
    try {
      return await axios.get(`${REACT_APP_SERVER_URL}${url}`, getOptionsWithToken());
    } catch (error) {
      console.log(error);
      throw error;
    }
  }


  const postData = async (url: string, params: any) => {
    try {
      return await axios.post(`${REACT_APP_SERVER_URL}${url}`, params, getOptionsWithToken());
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  return {
    getData,
    postData,
  }
};
