import React from "react";
import {DataGrid, GridCellParams} from '@mui/x-data-grid';
import {useTheme} from "@mui/material/styles";
import Box from '@mui/material/Box';
import Skeleton from "@mui/material/Skeleton";

import styles from './Result.module.css';


const rows = [
  {
    date: 1,
    id: '0001',
    mrn: '0001',
    patientName: '0001',
    visitType: '0001',
    location: '0001',
    notePrepDiseaseCategory: '0001',
    assignedVPP: '0001',
  },
  {
    date: 1,
    id: '0002',
    mrn: '0001',
    patientName: '0001',
    visitType: '0001',
    location: '0001',
    notePrepDiseaseCategory: '0001',
    assignedVPP: '0001',
  },
  {
    date: 1,
    id: '0003',
    mrn: '0001',
    patientName: '0001',
    visitType: '0001',
    location: '0001',
    notePrepDiseaseCategory: '0001',
    assignedVPP: '0001',
  },
  {
    date: 1,
    id: '0004',
    mrn: '0001',
    patientName: '0001',
    visitType: '0001',
    location: '0001',
    notePrepDiseaseCategory: '0001',
    assignedVPP: '0001',
  },
  {
    date: 1,
    id: '0005',
    mrn: '0001',
    patientName: '0001',
    visitType: '0001',
    location: '0001',
    notePrepDiseaseCategory: '0001',
    assignedVPP: '0001',
  },
  {
    date: 1,
    id: '0006',
    mrn: '0001',
    patientName: '0001',
    visitType: '0001',
    location: '0001',
    notePrepDiseaseCategory: '0001',
    assignedVPP: '0001',
  },
  {
    date: 1,
    id: '0007',
    mrn: '0001',
    patientName: '0001',
    visitType: '0001',
    location: '0001',
    notePrepDiseaseCategory: '0001',
    assignedVPP: '0001',
  },
]

const getRowClassName = (params: any) => params.indexRelativeToCurrentPage % 2 === 0 ? styles.oddRow : styles.evenRow;

const columns  = [
  {
    field: 'date',
    headerName: 'Date',
    width: 70,
    renderCell: (params: GridCellParams) => (
      <Skeleton variant="rectangular" width={60} height={24}/>
    )
  },
  {
    field: 'time',
    headerName: 'Time',
    width: 100,
    renderCell: (params: GridCellParams) => (
      <Skeleton variant="rectangular" width={80} height={24}/>
    )
  },
  {
    field: 'mrn',
    headerName: 'MRN',
    width: 100,
    renderCell: (params: GridCellParams) => (
      <Skeleton variant="rectangular" width={80} height={24}/>
    )
  },
  {
    field: 'npm_patient_id',
    headerName: 'NPM ID',
    width: 100,
    renderCell: (params: GridCellParams) => (
      <Skeleton variant="rectangular" width={100} height={24}/>
    )
  },
  {
    field: 'site',
    headerName: 'Site',
    width: 210,
    renderCell: (params: GridCellParams) => (
      <Skeleton variant="rectangular" width={100} height={24}/>
    )
  },
  {
    field: 'patientName',
    headerName: 'Patient Name',
    width: 200,
    renderCell: (params: GridCellParams) => (
      <Skeleton variant="rectangular" width={100} height={24}/>
    )
  },
  {
    field: 'scheduled_provider',
    headerName: 'Scheduled Provider',
    width: 200,
    renderCell: (params: GridCellParams) => (
      <Skeleton variant="rectangular" width={100} height={24}/>
    )
  },
  {
    field: 'consentStatus',
    headerName: 'Consent Status',
    width: 200,
    renderCell: (params: GridCellParams) => (
      <Skeleton variant="rectangular" width={180} height={24}/>
    )
  },
  {
    field: 'is_abstracted_yn',
    headerName: 'Abstraction Status',
    width: 150,
    renderCell: (params: GridCellParams) => (
      <Skeleton variant="rectangular" width={180} height={24}/>
    )
  },
  {
    field: 'visitType',
    headerName: 'Visit Type',
    minWidth: 150,
    renderCell: (params: GridCellParams) => (
      <Skeleton variant="rectangular" width={100} height={24}/>
    )
  },
];;

const ResultSkeleton: React.FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{backgroundColor: theme.palette.accentListTable.main, borderRadius: 2}}>
      <DataGrid
        autoHeight
        sx={{
          overflow: 'scroll', backgroundColor: theme.palette.accentListTable.main,
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            height: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            maxHeight: "168px !important",
            borderBottom: '2px solid #484444'
          }
        }}
        density={'compact'}
        rows={rows}
        checkboxSelection
        columns={columns}
        rowCount={rows.length}
        getRowClassName={getRowClassName}
      />
    </Box>
  );
};

export default ResultSkeleton;
