import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {DataGrid, GridCellParams, GridValueGetterParams} from '@mui/x-data-grid';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {useTheme} from "@mui/material/styles";
import Box from '@mui/material/Box';
import Chip from "@mui/material/Chip";
import AddTaskIcon from '@mui/icons-material/AddTask';
import {Range} from "react-date-range";
import Tooltip from "@mui/material/Tooltip";

import styles from './Result.module.css';
import {convertTextToCapitalCase} from "../../../utils/helperFunction";
import PatientSummary from "../patientSummary/PatientSummary";
import NoDataMessage from "../../common/noDataMessage/NoDataMessage";
import {APIS} from "../../../utils/constants";
import ResultSkeleton from './ResultSkeleton';
import useNetworkHandling from "../../../hooks/useNetworkHandling";
import {ClinicSiteProps, ProviderProps} from "../../../context/AppContext";
import {SearchValuesProps} from "../../common/search/SearchPopup";

export interface PatientProps {
  patient_uuid: string;
  clinic_site_id: string;
  clinic_site_name: string;
  provider: string;
  provider_name: string;
  visit_timestamp: string;
}

interface Props {
  addNewTask: (patients: PatientProps[]) => void;
  clinicalSites: ClinicSiteProps[];
  dateRange: Range;
  providers: ProviderProps[];
  searchParams: SearchValuesProps;
}

const getRowClassName = (params: any) => params.indexRelativeToCurrentPage % 2 === 0 ? styles.oddRow : styles.evenRow;

const getVisitDate = (params: GridValueGetterParams) => {
  return moment(params.row.visit_timestamp).format('MM/DD');
};

const getVisitTime = (params: GridValueGetterParams) => {
  return moment(params.row.visit_timestamp).format('hh:mm A');
};

const getMrn = (params: GridValueGetterParams) => {
  return params.row.patient.mrn;
};

const getNpmId = (params: GridValueGetterParams) => {
  return params.row.patient.patient_npm_id;
};

const getSite = (params: GridValueGetterParams) => {
  return params.row.patient.clinic_site_name;
};

const getPatientName = (params: GridValueGetterParams) => {
  return `${params.row.patient.first_name}, ${params.row.patient.last_name}`;
};

const getProviderName = (params: GridValueGetterParams) => {
  return `${params.row.attending_doctor_first_name} ${params.row.attending_doctor_last_name}`;
};

const getVisitType = (params: GridValueGetterParams) => {
  return params.row.visit_type;
};

const getConsentStatus = (params: GridValueGetterParams) => {
  return convertTextToCapitalCase(params.row?.consent?.consent_status)
};

const Result: React.FC<Props> = ({addNewTask, clinicalSites, dateRange, providers, searchParams}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const {getData} = useNetworkHandling();
  const theme = useTheme();
  const [rows, setRows] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [patientId, setPatientId] = useState<string | null>(null);
  const [apiRequested, setApiRequested] = useState<boolean>(false);

  const hidePatientSummaryModal = () => setPatientId(null);

  const handleSelectionModelChange = (newSelection: any) => {
    setSelectedRows(newSelection);
  };

  const addTask = () => {
    const selectedSchedules = rows.filter(row => !!selectedRows.find(scheduleId => scheduleId === row.schedule_uuid));

    addNewTask(selectedSchedules.map(schedule => {
      return {
        patient_uuid: schedule.patient.patient_uuid,
        clinic_site_id: schedule.patient.clinic_site,
        clinic_site_name: schedule.patient.clinic_site_name,
        provider: schedule.attending_doctor_id,
        provider_name: `${schedule.attending_doctor_first_name} ${schedule.attending_doctor_last_name}`,
        visit_timestamp: schedule.visit_timestamp,
      }
    }));
  }

  const getPatients = async (pageNumber: number, sites: ClinicSiteProps[], scheduleProviders: ProviderProps[], range: Range, searchParams: SearchValuesProps) => {
    try {
      setApiRequested(true);
      setRows([]);
      const url = `${APIS.SCHEDULES}?page=${pageNumber}${sites?.length > 0 ? `&clinic_sites=${sites.map((one: ClinicSiteProps) => one.site_uuid).join(',')}` : ''}${scheduleProviders?.length > 0 ? `&provider=${scheduleProviders.map(one => one.attending_doctor_uuid).join(',')}` : ''}${range?.startDate ? `&start_date_int=${Math.floor(moment(range?.startDate).valueOf() / 1000)}&end_date_int=${Math.floor(moment(range?.endDate).valueOf() / 1000)}` : ''}${searchParams?.lastName.length > 0 ? `&last_name=${searchParams?.lastName}` : ''}${searchParams?.firstName.length > 0 ? `&first_name=${searchParams?.firstName}` : ''}${searchParams?.mrnNo.length > 0 ? `&mrn=${searchParams?.mrnNo}` : ''}${searchParams?.npmId.length > 0 ? `&npm_id=${searchParams?.npmId}` : ''}`;
      const {data} = await getData(encodeURI(url));
      setRows(data);
    } catch (e) {
      console.log(e);
    } finally {
      setApiRequested(false);
    }
  };

  const getConsentColor = (status: any) => {
    switch (status) {
      case 'CONSENTED':
        return {
          bgcolor: theme.palette.consentStatusConsentedBackground.main,
          color: theme.palette.consentStatusConsentedText.main
        };
      case 'NOT_READY_TO_CONSENT':
        return {
          bgcolor: theme.palette.consentStatusNotReadyToConsentBackground.main,
          color: theme.palette.consentStatusNotReadyToConsentText.main
        };
      case 'READY_TO_CONSENT':
        return {
          bgcolor: theme.palette.consentStatusReadyToConsentBackground.main,
          color: theme.palette.consentStatusReadyToConsentText.main
        };

      case 'DISEASE_NOT_OF_INTEREST':
        return {
          bgcolor: theme.palette.consentStatusDiseaseNotOfInterestBackground.main,
          color: theme.palette.consentStatusDiseaseNotOfInterestText.main
        };

      case 'UNABLE_TO_CONSENT':
        return {
          bgcolor: theme.palette.consentStatusUnableToConsentBackground.main,
          color: theme.palette.consentStatusUnableToConsentText.main
        };

      case 'REQUIRES RE-CONSENT':
        return {
          bgcolor: theme.palette.consentStatusRequiresReConsentBackground.main,
          color: theme.palette.consentStatusRequiresReConsentText.main
        };

      case 'FULL_CONSENT_WITHDRAWN':
        return {
          bgcolor: theme.palette.consentStatusFullConsentWithdrawnBackground.main,
          color: theme.palette.consentStatusFullConsentWithdrawnText.main
        };

      case 'PARTIAL_CONSENT_WITHDRAWN':
        return {
          bgcolor: theme.palette.consentStatusPartialConsentWithdrawnBackground.main,
          color: theme.palette.consentStatusPartialConsentWithdrawnText.main
        };

      default:
        return {
          bgcolor: theme.palette.consentStatusDeclinedBackground.main,
          color: theme.palette.consentStatusDeclinedText.main
        };
    }
  };

  // Consent Status, Abstraction Status, Visit Type, and Diagnosis Code
  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      width: 70,
      valueGetter: getVisitDate,
      renderCell: (params: GridCellParams) => (
        <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
          {moment(params.row.visit_timestamp).format('MM/DD')}
        </Typography>
      )
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 100,
      valueGetter: getVisitTime,
      renderCell: (params: GridCellParams) => (
        <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
          {moment(params.row.visit_timestamp).format('hh:mm A')}
        </Typography>
      )
    },
    {
      field: 'mrn',
      headerName: 'MRN',
      width: 120,
      valueGetter: getMrn,
      renderCell: (params: GridCellParams) => {
        const handleClick = (event: React.MouseEvent) => {
          event.stopPropagation();
          navigator.clipboard.writeText(params.row.patient.mrn);
        };

        return (
          <Tooltip
            title="Click to Copy"
          >
            <Chip variant="filled" size={'small'} label={params.row.patient.mrn} sx={{
              bgcolor: theme.palette.mrnBackground.main,
              color: theme.palette.blackRegular.main,
              padding: 1,
              borderRadius: 2,
              fontWeight: 'bold'
            }}
                  onClick={(event) => handleClick(event)}/>
          </Tooltip>
        )
      }
    },
    {
      field: 'npm_patient_id',
      headerName: 'NPM ID',
      width: 100,
      valueGetter: getNpmId,
      renderCell: (params: GridCellParams) => (
        <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
          {params.row.patient.npm_patient_id}
        </Typography>
      )
    },
    {
      field: 'site',
      headerName: 'Site',
      width: 210,
      valueGetter: getSite,
      renderCell: (params: GridCellParams) => (
        <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
          {params.row.patient.clinic_site_name}
        </Typography>
      )
    },
    {
      field: 'patientName',
      headerName: 'Patient Name',
      width: 200,
      valueGetter: getPatientName,
      renderCell: (params: GridCellParams) => (
        <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
          {params.row.patient.first_name} {params.row.patient.last_name}
        </Typography>
      )
    },
    {
      field: 'scheduled_provider',
      headerName: 'Scheduled Provider',
      width: 200,
      valueGetter: getProviderName,
      renderCell: (params: GridCellParams) => (
        <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
          {params.row.attending_doctor_first_name} {params.row.attending_doctor_last_name}
        </Typography>
      )
    },
    {
      field: 'consentStatus',
      headerName: 'Consent Status',
      width: 200,
      valueGetter: getConsentStatus,
      renderCell: (params: GridCellParams) => (
        params.row?.patient?.patient_consent?.consent_status ?
          <Chip size="small" label={convertTextToCapitalCase(params.row?.patient?.patient_consent?.consent_status)}
                sx={getConsentColor(params.row?.patient?.patient_consent?.consent_status)}/> : ''
      )
    },
    {
      field: 'is_abstracted_yn',
      headerName: 'Abstraction Status',
      width: 150,
      valueGetter: getConsentStatus,
      renderCell: (params: GridCellParams) => (
        params.row?.is_abstracted_yn ?
          <Chip size="small" label={'Abstracted'}
                sx={{
                  bgcolor: theme.palette.abstractionStatusBackground.main,
                  color: theme.palette.abstractionStatusText.main
                }}/> : ''
      )
    },
    {
      field: 'visitType',
      headerName: 'Visit Type',
      minWidth: 150,
      valueGetter: getVisitType,
      renderCell: (params: GridCellParams) => (
        <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
          {params.row.visit_type}
        </Typography>
      )
    },
  ];

  useEffect(() => {
    getPatients(pageNumber, clinicalSites, providers, dateRange, searchParams).then(() => {
    });
  }, [pageNumber,clinicalSites, providers, dateRange, searchParams]);

  if (apiRequested) {
    return <ResultSkeleton />;
  }

  return (
    <>
      <Box sx={{backgroundColor: theme.palette.accentListTable.main, borderRadius: 2}}>
        {selectedRows.length > 0 && (
          <Box p={2}>
            <IconButton aria-label="Add Task" color="primary" className={styles.actionButton}
                        onClick={addTask}>
              <AddTaskIcon/>
            </IconButton>
          </Box>
        )}

        <DataGrid
          autoHeight
          sx={{
            overflow: 'scroll', backgroundColor: theme.palette.accentListTable.main,
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal"
            },
            "& .MuiDataGrid-columnHeader": {
              height: "unset !important"
            },
            "& .MuiDataGrid-columnHeaders": {
              maxHeight: "168px !important",
              borderBottom: '2px solid #484444'
            }
          }}
          getRowId={(row: any) => row.schedule_uuid}
          slots={{
            noRowsOverlay: () => <NoDataMessage message={'No records available at the moment'}/>,
          }}
          initialState={{
            pagination: {
              paginationModel: {page: 0, pageSize: 10},
            },
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          density={'compact'}
          checkboxSelection
          rows={rows}
          columns={columns}
          rowCount={rows.length}
          getRowClassName={getRowClassName}
          onRowSelectionModelChange={handleSelectionModelChange}
        />
      </Box>
      <PatientSummary patientId={patientId} showDialog={!!patientId} handleClose={hidePatientSummaryModal}/>
    </>
  );
};

export default Result;
