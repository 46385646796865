import React from 'react';
import Button, {ButtonProps} from '@mui/material/Button';
import styles from './PrimaryButton.module.css';

/**
 * Component is used to render the filled button
 * @param props
 * @constructor
 */
const PrimaryButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button {...props} variant="contained" className={styles.primaryButton}>
      {props.children}
    </Button>
  );
};

export default PrimaryButton;
