import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";

import UserInfo from "../userInfo/UserInfo";

interface HeaderProps {
  pageTitle: string;
}

/**
 * Component is used to render the header of the page
 * @param pageTitle: title of the page
 * @constructor
 */
const Header: React.FC<HeaderProps> = ({pageTitle,}) => {
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{bgcolor: '#fff'}}>
        <Toolbar variant="dense">
          <Box pl={8} sx={{ flexGrow: 1 }} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h1'} style={{color: theme.palette.blackBold.main}}>{pageTitle}</Typography>
            <UserInfo />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
};

export default Header;
