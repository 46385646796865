export const getInitials = (name: string | undefined) => {
  if (name) {
    return name
      .split(' ')
      .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
      .join('')
      .toUpperCase() || '';
  }

  return '';
};

export const stringToColor = (string: string | undefined) => {
  if (string) {
    let hashValue = 0;
    for (let i = 0; i < string.length; i++) {
      hashValue = string.charCodeAt(i) + ((hashValue << 5) - hashValue);
    }

    // Convert the hash value to RGB values
    const r = (hashValue & 0xFF0000) >> 16;
    const g = (hashValue & 0x00FF00) >> 8;
    const b = hashValue & 0x0000FF;

    // Darken the color by reducing brightness or saturation
    const darkeningFactor = 0.7;
    const darkenedR = Math.floor(r * darkeningFactor);
    const darkenedG = Math.floor(g * darkeningFactor);
    const darkenedB = Math.floor(b * darkeningFactor);

    return `rgb(${darkenedR}, ${darkenedG}, ${darkenedB})`;
  } else {
    return '#f00';
  }
}

export const convertTextToCapitalCase = (inputText: string) => {
  if (inputText) {
    // Replace underscores with spaces
    let textWithSpaces = inputText.replace(/_/g, ' ');

    // Convert to capital case
    let words = textWithSpaces.split(' ');
    let capitalWords = words.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return capitalWords.join(' ');
  }
  return '';
}
