import React, {useState} from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import {Link, useLocation} from "react-router-dom";
import {useOktaAuth} from "@okta/okta-react";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import styles from './SideDrawer.module.css';
import ShortLogo from '../../../assets/short-logo.svg';
import Logo from '../../../assets/logo.svg';
import {SIDE_MENU} from "../../../utils/constants";

interface SideDrawerProps {
}

/**
 * Component is used to render the side drawer on the page to navigate between the pages
 */
const SideDrawer: React.FC<SideDrawerProps> = React.memo(() => {
  const {oktaAuth} = useOktaAuth();
  const location = useLocation();
  const theme = useTheme();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  }

  const logout = async () => oktaAuth.signOut({
    revokeAccessToken: true,
    revokeRefreshToken: true,
    clearTokensBeforeRedirect: true
  });

  return (
    <Drawer
      className={styles.drawerContainer}
      variant="permanent"
      anchor="left"
      open={isMenuOpen}
    >
      {isMenuOpen ? (
        <Box py={4} className={`${styles.menuDrawer} ${styles.open}`}>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <IconButton
              aria-label="menu"
              onClick={toggleMenu}
              className={`${styles.menuIcon} ${styles.open}`}
            >
              <CloseIcon style={{color: theme.palette.brandColorPrimary.main}}/>
            </IconButton>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <img src={Logo} className={styles.logo} alt={'N power logo'}/>
          </Box>
          <Box className={styles.menuList}>
            {SIDE_MENU.map(menu => (
              <Link to={menu.path} key={menu.name}
                    className={`${styles.menu} ${location.pathname === menu.path ? styles.active : ''}`}>
                <span className={styles.menuItemIcon}>
                  <menu.icon sx={{fontSize: 24, color: theme.palette.accentListTable.main}}/>
                </span>
                <Typography variant={'body1'} color={theme.palette.brandColorPrimary.main}
                            className={styles.menuItemText}>{menu.label}</Typography>
              </Link>
            ))}
          </Box>
          <Box className={styles.logoutContainer}>
            <Button onClick={logout} className={styles.logout}>
              <span className={styles.menuItemIcon}>
                  <LogoutOutlinedIcon sx={{fontSize: 24, color: theme.palette.warning.main}}/>
                </span>
              <Typography variant={'body1'} color={theme.palette.brandColorPrimary.main}
                          className={styles.menuItemText}>Logout</Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box py={4} className={`${styles.menuDrawer} ${styles.closed}`}>
          <Box textAlign={'center'}>
            <IconButton
              aria-label="menu"
              onClick={toggleMenu}
              className={styles.menuIcon}
            >
              <MenuIcon style={{color: theme.palette.brandColorPrimary.main}}/>
            </IconButton>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <img src={ShortLogo} className={styles.logo} alt={'N power logo'}/>
          </Box>
          <Box className={styles.menuList}>
            {SIDE_MENU.map(menu => (
              <Link to={menu.path} key={menu.name}
                    className={`${styles.menu} ${location.pathname === menu.path ? styles.active : ''}`}>
                <span className={styles.menuItemIcon}>
                  <menu.icon sx={{fontSize: 24, color: theme.palette.accentListTable.main}}/>
                </span>
              </Link>
            ))}
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Button onClick={logout}>
              <LogoutOutlinedIcon sx={{fontSize: 24, color: theme.palette.warning.main}}/>
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
});

export default SideDrawer;
