import React from "react";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {useTheme} from "@mui/material/styles";
import Chip from "@mui/material/Chip";

import styles from "./TaskDetails.module.css";
import ProgressStatus from "./progressStatus/ProgressStatus";
import Comments, {CommentProps} from "./comments/Comments";
import TaskSkeleton from "./TaskSkeleton";
import {useAppContext} from "../../../context/AppContext";
import {TaskProps} from "../../../pages/tasks/Tasks";
import Priority from "../priority/Priority";
import {stringToColor} from "../../../utils/helperFunction";
import Assignee from "./assignee/Assignee";
import Description from "./description/Description";

interface Props {
  task: TaskProps;
  clearSelectedTask: () => void;
  updateTask: (taskId: string, task: TaskProps) => void;
}

const TaskDetails: React.FC<Props> = ({task, clearSelectedTask, updateTask}) => {
  const theme = useTheme();
  const {sites} = useAppContext();

  const addComment = (comment: CommentProps) => {
    const newTask = Object.assign({}, task, {
      comments: task.comments.concat([comment]),
    });
    updateTask(task.id, newTask);
  };

  const updateTaskDescription = (description: string) => {
    const newTask = Object.assign({}, task, {
      description,
    });
    updateTask(task.id, newTask);
  };

  if (!task) {
    return <TaskSkeleton/>
  }

  return (
    <Box pr={1} pl={2} className={styles.container}>
      <Box display={'flex'} mb={2} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'}>
          <Box>
            <Priority taskPriority={task.priority}/>
          </Box>
          <Box ml={1}>
            <Chip variant="filled" size={'small'} label={task.patient_mrn} sx={{
              bgcolor: theme.palette.mrnBackground.main,
              color: theme.palette.blackRegular.main,
              padding: 1,
              borderRadius: 2,
              fontWeight: 'bold'
            }}/>
          </Box>
          <Box ml={1}>
            <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
              {task.patient_name}
            </Typography>
          </Box>
        </Box>
        <IconButton aria-label="close" onClick={clearSelectedTask}>
          <CloseOutlinedIcon/>
        </IconButton>
      </Box>
      <Typography variant={'h3'} color={theme.palette.blackBold.main}>
        {task.title}
      </Typography>
      <Box mt={2}>
        <ProgressStatus taskId={task.id} status={task.status} updateTask={updateTask} view={task.task_view}/>
      </Box>
      <Box mt={2}>
        <Description description={task?.description} taskId={task?.id} updateTaskDescription={updateTaskDescription}/>
      </Box>
      <Box mt={2} className={styles.details}>
        <Box p={1} className={styles.accordionTitle}>
          <Typography variant={'h5'} color={theme.palette.blackBold.main}>
            Details
          </Typography>
        </Box>
        <Box display={'flex'} p={1}>
          <Box width={100}>
            <Typography variant={'h4'} color={theme.palette.blackRegular.main}>
              Task Author
            </Typography>
          </Box>
          {!!task.created_by ? (
            <Box display={'flex'} flex={1}>
              <Avatar alt={task.created_by}
                      sx={{width: 24, height: 24, bgcolor: stringToColor(task.created_by), marginRight: 1}}>
                {task?.created_by[0].toUpperCase()}
              </Avatar>
              <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
                {task.created_by}
              </Typography>
            </Box>
          ) : (
            <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
              Cerberus
            </Typography>
          )}
        </Box>
        <Box display={'flex'} p={1}>
          <Box width={100}>
            <Typography variant={'h4'} color={theme.palette.blackRegular.main}>
              Due
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
              {moment(task.due_date).format('MM/DD/YYYY hh:mm A')}
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'} p={1}>
          <Box width={100}>
            <Typography variant={'h4'} color={theme.palette.blackRegular.main}>
              Assignee
            </Typography>
          </Box>
          <Box flex={1} display={'flex'} alignItems={'center'}>
            <Assignee view={task.task_view} taskId={task?.id} assignee={task?.assignTo} updateTask={updateTask}/>
          </Box>
        </Box>
        <Box display={'flex'} p={1}>
          <Box width={100}>
            <Typography variant={'h4'} color={theme.palette.blackRegular.main}>
              NPM ID
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
              {task.patient_npm_id}
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'} p={1}>
          <Box width={100}>
            <Typography variant={'h4'} color={theme.palette.blackRegular.main}>
              Site
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
              {sites[task.clinicSite]}
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'} p={1}>
          <Box width={100}>
            <Typography variant={'h4'} color={theme.palette.blackRegular.main}>
              Provider
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
              {task.provider}
            </Typography>
          </Box>
        </Box>

      </Box>
      <Box mt={2}>
        <Comments comments={task.comments} taskId={task?.id} addComment={addComment}/>
      </Box>
    </Box>
  )
};

export default TaskDetails;
