import {useOktaAuth} from "@okta/okta-react";
import {Navigate} from "react-router-dom";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import {ROUTE_ACTIONS} from "../../utils/constants";
import PrimaryButton from "../../components/common/primaryButton/PrimaryButton";
import Logo from '../../assets/logo.svg';

/**
 * Component is rendered when user enters root of the app
 * It checks whether the user is logged in or not and accordingly it either shows login button or navigates to tasks listing page
 * @constructor
 */
const Home = () => {
  const theme = useTheme();
  const {oktaAuth, authState} = useOktaAuth();

  /**
   * Function is triggered when user clicks on login button
   */
  const login = async () => oktaAuth.signInWithRedirect();

  /**
   * If auth state is unknown show loader
   */
  if (!authState) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress/>
      </Box>
    );
  }

  /**
   * If user is not authenticated, show login button
   */
  if (!authState.isAuthenticated) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}
           style={{backgroundColor: theme.palette.greyRegular.main}}>
        <Box>
          <Box mb={2} display={'flex'} alignItems={'center'}>
            <img src={Logo} alt={'N power logo'}/>
            <Typography variant={'h2'} style={{color: theme.palette.brandColorPrimary.main}}>Cerberus</Typography>
          </Box>
          <PrimaryButton onClick={login}>
            Sign in with Okta
          </PrimaryButton>
        </Box>
      </Box>
    );
  }

  /**
   * Navigate to the tasks page if user is logged in
   */
  return (
    <Navigate to={ROUTE_ACTIONS.TASKS}/>
  );
};

export default Home;
