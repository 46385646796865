import React from 'react';
import Dialog from '@mui/material/Dialog';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import DialogContent from '@mui/material/DialogContent';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import AddTaskIcon from "@mui/icons-material/AddTask";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import moment from "moment";

import PrimaryButton from "../../common/primaryButton/PrimaryButton";
import SecondaryButton from "../../common/secondaryButton/SecondaryButton";
import styles from "./AddTask.module.css";
import {CategoryProps, PriorityProps, useAppContext, UserProps} from "../../../context/AppContext";
import {APIS} from "../../../utils/constants";
import {FormikHelpers} from "formik/dist/types";
import {NotificationMessageProps} from "../../../pages/patientSchedule/PatientSchedule";
import {PatientProps} from "../result/Result";
import useNetworkHandling from "../../../hooks/useNetworkHandling";

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  title: Yup.string().nullable().required('Title is required'),
  assignTo: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
  }).nullable().required('Assign to is required'),
  priority: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
  }).nullable().required('Priority is required'),
  description: Yup.string(),
});

export interface TaskFormValues {
  title: string;
  assignTo: UserProps | null;
  priority: PriorityProps | null;
  description: string;
}

export interface AddTaskProps {
  selectedPatients: PatientProps[];
  open: boolean;
  handleClose: () => void;
  updateNotificationMessage: (message: NotificationMessageProps) => void;
}

const initialValues: TaskFormValues = {
  title: '',
  assignTo: null,
  priority: null,
  description: ''
};


const AddTask: React.FC<AddTaskProps> = ({selectedPatients, open, handleClose, updateNotificationMessage}) => {
  const {postData} = useNetworkHandling();
  const theme = useTheme();
  const {categories, users, priorities, user} = useAppContext();

  // Function to handle form submission
  const handleSubmit = async (values: TaskFormValues, formikHelpers: FormikHelpers<TaskFormValues>) => {
    try {
      await postData(APIS.TASKS, selectedPatients.map(patient => {
        return {
          title: values.title,
          clinic_site_id: patient.clinic_site_id,
          provider: patient.provider_name,
          priority: values.priority?.id,
          dueDate: Math.floor(moment(patient.visit_timestamp).valueOf() / 1000),
          description: values.description,
          vpp_assign_to_id: values.assignTo?.id,
          categories: [],
          patient_uuid: patient.patient_uuid,
          status: "To do",
          view: 'vpp',
        }
      }));
      updateNotificationMessage({
        message: 'Task added successfully',
        severity: 'success'
      });
      handleClose();
    } catch (e) {
      updateNotificationMessage({
        message: 'Something went wrong, please try again',
        severity: 'error'
      });
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
          <Box className={styles.emptyButton}/>
          <Box display={'flex'}>
            <Box className={styles.addTaskIcon}>
              <AddTaskIcon color={"primary"}/>
            </Box>
            <Typography variant={'h2'} color={theme.palette.hyperLink.main}>
              Create New Task
            </Typography>
          </Box>
          <Box>
            <IconButton aria-label="Close Modal" color="primary" onClick={handleClose} size={'small'}>
              <CloseIcon/>
            </IconButton>
          </Box>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({values, handleChange, setFieldValue, errors, touched, isSubmitting}) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography variant={'h6'} color={theme.palette.hyperLink.main}>
                        {selectedPatients.length} patients selected.
                      </Typography>
                      <Box display={'flex'} justifyContent={'flex-end'}>
                        <Typography variant={'h6'} color={theme.palette.blackBold.main}>
                          Task created by:&nbsp;
                        </Typography>
                        <Typography variant={'h6'} color={theme.palette.blackRegular.main}>
                          {user.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} mt={1} alignItems={'center'}>
                      {selectedPatients?.every((one: PatientProps) => one.clinic_site_id === selectedPatients[0].clinic_site_id) && (
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography variant={'h6'} color={theme.palette.blackBold.main}>
                            Site:&nbsp;
                          </Typography>
                          <Typography variant={'h6'} color={theme.palette.blackRegular.main}>
                            {selectedPatients[0]?.clinic_site_name}
                          </Typography>
                        </Box>
                      )}
                      {selectedPatients?.every((one: PatientProps) => one.provider === selectedPatients[0].provider) && (
                        <Box display={'flex'} justifyContent={'flex-end'}>
                          <Typography variant={'h6'} color={theme.palette.blackBold.main}>
                            Provider:&nbsp;
                          </Typography>
                          <Typography variant={'h6'} color={theme.palette.blackRegular.main}>
                            {selectedPatients[0]?.provider_name}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>

                  <Grid item sm={12}>
                    <Autocomplete
                      size={'small'}
                      options={categories.map((one: CategoryProps) => one.name)}
                      freeSolo
                      value={values.title}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setFieldValue('title', newValue);
                        } else {
                          setFieldValue('title', '');
                        }
                      }}
                      inputValue={values.title}
                      onInputChange={(event: any, newInputValue: string) => {
                        if (newInputValue) {
                          setFieldValue('title', newInputValue);
                        } else {
                          setFieldValue('title', '');
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Title"
                          error={Boolean(errors.title) && Boolean(touched.title)}
                          helperText={touched.title && errors.title}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Autocomplete
                      disabled={isSubmitting}
                      fullWidth
                      size={'small'}
                      id="assignTo"
                      getOptionLabel={(option: UserProps) => option.name}
                      value={values.assignTo}
                      onChange={(event, newValue) => {
                        setFieldValue('assignTo', newValue);
                      }}
                      options={users}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Assign To"
                          error={!!errors.assignTo && touched.assignTo}
                          helperText={touched.assignTo && errors.assignTo}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Autocomplete
                      disabled={isSubmitting}
                      fullWidth
                      size={'small'}
                      id="priority"
                      value={values.priority}
                      getOptionLabel={(option: PriorityProps) => option.name}
                      onChange={(event, newValue) => {
                        setFieldValue('priority', newValue);
                      }}
                      options={priorities}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Priority"
                          error={!!errors.priority && touched.priority}
                          helperText={touched.priority && errors.priority}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <TextField
                      disabled={isSubmitting}
                      fullWidth
                      size={'small'}
                      name="description"
                      type="text"
                      label="Description"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('description', event.target.value);
                      }}
                      error={Boolean(errors.description) && Boolean(touched.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Box>
                      <Divider/>
                    </Box>
                    <Box justifyContent={'flex-end'} display={'flex'} mt={2}>
                      <Box mr={1}>
                        <SecondaryButton disabled={isSubmitting} type={'button'} size={'small'} onClick={handleClose}>
                          Cancel
                        </SecondaryButton>
                      </Box>

                      <PrimaryButton disabled={isSubmitting} type={'submit'} size={'small'}
                                     endIcon={isSubmitting ? <CircularProgress size={14}/> : <SendIcon/>}>
                        Assign Task
                      </PrimaryButton>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
};

export default AddTask;
