import {createTheme} from '@mui/material/styles';
import {SimplePaletteColorOptions} from "@mui/material";

declare module '@mui/material/styles' {
  interface Palette {
    brandColorPrimary: SimplePaletteColorOptions;
    brandColorSecondary: SimplePaletteColorOptions;
    accentListTable: SimplePaletteColorOptions;
    disabledBackground: SimplePaletteColorOptions;
    hyperLink: SimplePaletteColorOptions;
    ddsTeam: SimplePaletteColorOptions;
    dhsTeam: SimplePaletteColorOptions;
    vppTeam: SimplePaletteColorOptions;
    blackRegular: SimplePaletteColorOptions;
    blackBold: SimplePaletteColorOptions;
    greyRegular: SimplePaletteColorOptions;
    priorityHighBackground: SimplePaletteColorOptions;
    priorityMediumBackground: SimplePaletteColorOptions;
    priorityLowBackground: SimplePaletteColorOptions;
    priorityHighText: SimplePaletteColorOptions;
    priorityMediumText: SimplePaletteColorOptions;
    priorityLowText: SimplePaletteColorOptions;

    consentStatusConsentedBackground: SimplePaletteColorOptions;
    consentStatusNotReadyToConsentBackground: SimplePaletteColorOptions;
    consentStatusReadyToConsentBackground: SimplePaletteColorOptions;
    consentStatusDiseaseNotOfInterestBackground: SimplePaletteColorOptions;
    consentStatusUnableToConsentBackground: SimplePaletteColorOptions;
    consentStatusRequiresReConsentBackground: SimplePaletteColorOptions;
    consentStatusFullConsentWithdrawnBackground: SimplePaletteColorOptions;
    consentStatusPartialConsentWithdrawnBackground: SimplePaletteColorOptions;
    consentStatusDeclinedBackground: SimplePaletteColorOptions;
    consentStatusConsentedText: SimplePaletteColorOptions;
    consentStatusNotReadyToConsentText: SimplePaletteColorOptions;
    consentStatusReadyToConsentText: SimplePaletteColorOptions;
    consentStatusDiseaseNotOfInterestText: SimplePaletteColorOptions;
    consentStatusUnableToConsentText: SimplePaletteColorOptions;
    consentStatusRequiresReConsentText: SimplePaletteColorOptions;
    consentStatusFullConsentWithdrawnText: SimplePaletteColorOptions;
    consentStatusPartialConsentWithdrawnText: SimplePaletteColorOptions;
    consentStatusDeclinedText: SimplePaletteColorOptions;

    progressStatusTodoBackground: SimplePaletteColorOptions;
    progressStatusInProgressBackground: SimplePaletteColorOptions;
    progressStatusNotePreppedBackground: SimplePaletteColorOptions;
    progressStatusNotePreppedExpectingDocsBackground: SimplePaletteColorOptions;
    progressStatusNotePreppedReviewNeededBackground: SimplePaletteColorOptions;
    progressStatusSignedBackground: SimplePaletteColorOptions;
    progressStatusCompletedBackground: SimplePaletteColorOptions;
    progressStatusNoWorkNeededBackground: SimplePaletteColorOptions;
    progressStatusTodoText: SimplePaletteColorOptions;
    progressStatusInProgressText: SimplePaletteColorOptions;
    progressStatusNotePreppedText: SimplePaletteColorOptions;
    progressStatusNotePreppedExpectingDocsText: SimplePaletteColorOptions;
    progressStatusNotePreppedReviewNeededText: SimplePaletteColorOptions;
    progressStatusSignedText: SimplePaletteColorOptions;
    progressStatusCompletedText: SimplePaletteColorOptions;
    progressStatusNoWorkNeededText: SimplePaletteColorOptions;

    mrnBackground: SimplePaletteColorOptions;
    abstractionStatusBackground: SimplePaletteColorOptions;
    abstractionStatusText: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    brandColorPrimary: SimplePaletteColorOptions;
    brandColorSecondary: SimplePaletteColorOptions;
    disabledBackground: SimplePaletteColorOptions;
    accentListTable: SimplePaletteColorOptions;
    hyperLink: SimplePaletteColorOptions;
    ddsTeam: SimplePaletteColorOptions;
    dhsTeam: SimplePaletteColorOptions;
    vppTeam: SimplePaletteColorOptions;
    blackRegular: SimplePaletteColorOptions;
    blackBold: SimplePaletteColorOptions;
    greyRegular: SimplePaletteColorOptions;

    priorityHighBackground: SimplePaletteColorOptions;
    priorityMediumBackground: SimplePaletteColorOptions;
    priorityLowBackground: SimplePaletteColorOptions;
    priorityHighText: SimplePaletteColorOptions;
    priorityMediumText: SimplePaletteColorOptions;
    priorityLowText: SimplePaletteColorOptions;

    consentStatusConsentedBackground: SimplePaletteColorOptions;
    consentStatusNotReadyToConsentBackground: SimplePaletteColorOptions;
    consentStatusReadyToConsentBackground: SimplePaletteColorOptions;
    consentStatusDiseaseNotOfInterestBackground: SimplePaletteColorOptions;
    consentStatusUnableToConsentBackground: SimplePaletteColorOptions;
    consentStatusRequiresReConsentBackground: SimplePaletteColorOptions;
    consentStatusFullConsentWithdrawnBackground: SimplePaletteColorOptions;
    consentStatusPartialConsentWithdrawnBackground: SimplePaletteColorOptions;
    consentStatusDeclinedBackground: SimplePaletteColorOptions;
    consentStatusConsentedText: SimplePaletteColorOptions;
    consentStatusNotReadyToConsentText: SimplePaletteColorOptions;
    consentStatusReadyToConsentText: SimplePaletteColorOptions;
    consentStatusDiseaseNotOfInterestText: SimplePaletteColorOptions;
    consentStatusUnableToConsentText: SimplePaletteColorOptions;
    consentStatusRequiresReConsentText: SimplePaletteColorOptions;
    consentStatusFullConsentWithdrawnText: SimplePaletteColorOptions;
    consentStatusPartialConsentWithdrawnText: SimplePaletteColorOptions;
    consentStatusDeclinedText: SimplePaletteColorOptions;

    progressStatusTodoBackground: SimplePaletteColorOptions;
    progressStatusInProgressBackground: SimplePaletteColorOptions;
    progressStatusNotePreppedBackground: SimplePaletteColorOptions;
    progressStatusNotePreppedExpectingDocsBackground: SimplePaletteColorOptions;
    progressStatusNotePreppedReviewNeededBackground: SimplePaletteColorOptions;
    progressStatusSignedBackground: SimplePaletteColorOptions;
    progressStatusCompletedBackground: SimplePaletteColorOptions;
    progressStatusNoWorkNeededBackground: SimplePaletteColorOptions;
    progressStatusTodoText: SimplePaletteColorOptions;
    progressStatusInProgressText: SimplePaletteColorOptions;
    progressStatusNotePreppedText: SimplePaletteColorOptions;
    progressStatusNotePreppedExpectingDocsText: SimplePaletteColorOptions;
    progressStatusNotePreppedReviewNeededText: SimplePaletteColorOptions;
    progressStatusSignedText: SimplePaletteColorOptions;
    progressStatusCompletedText: SimplePaletteColorOptions;
    progressStatusNoWorkNeededText: SimplePaletteColorOptions;

    mrnBackground: SimplePaletteColorOptions;
    abstractionStatusBackground: SimplePaletteColorOptions;
    abstractionStatusText: SimplePaletteColorOptions;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '24px',
      fontWeight: "bold",
    },
    h2: {
      fontSize: '20px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '16px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '14px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '14px',
      fontWeight: 500,
    },
    h6: {
      fontSize: '14px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '12px',
    },
  },
  palette: {
    primary: {
      main: '#1A4FBA',
    },
    secondary: {
      main: '#659AE9',
    },
    warning: {
      main: 'rgba(228, 3, 3, 0.50)',
    },
    success: {
      main: '#71DDB1',
    },
    brandColorPrimary: {
      main: '#FDFEFF',
    },
    brandColorSecondary: {
      main: '#659AE9',
    },
    accentListTable: {
      main: '#EBF3FC',
    },
    disabledBackground: {
      main: '#919EAB',
    },
    hyperLink: {
      main: '#0A5AD1',
    },
    ddsTeam: {
      main: '#F9C84B',
    },
    dhsTeam: {
      main: '#2CC4CE',
    },
    vppTeam: {
      main: '#C98AFB',
    },
    blackRegular: {
      main: '#6E7191',
    },
    blackBold: {
      main: '#484444'
    },
    greyRegular: {
      main: '#85A5C2'
    },
    consentStatusConsentedBackground: {
      main: '#CCF8DC',
    },
    consentStatusNotReadyToConsentBackground: {
      main: '#FFDCBB',
    },
    consentStatusReadyToConsentBackground: {
      main: '#D5F7FB',
    },
    consentStatusDiseaseNotOfInterestBackground: {
      main: '#F3F1F1',
    },
    consentStatusUnableToConsentBackground: {
      main: '#FFE2DB',
    },
    consentStatusRequiresReConsentBackground: {
      main: '#FFF1BE',
    },
    consentStatusFullConsentWithdrawnBackground: {
      main: '#ABC8E1',
    },
    consentStatusPartialConsentWithdrawnBackground: {
      main: '#A3CCEF',
    },
    consentStatusDeclinedBackground: {
      main: '#D1D8FF',
    },
    consentStatusConsentedText: {
      main: '#229D4E',
    },
    consentStatusNotReadyToConsentText: {
      main: '#FF8F27',
    },
    consentStatusReadyToConsentText: {
      main: '#52A7B4',
    },
    consentStatusDiseaseNotOfInterestText: {
      main: '#6F6C7D',
    },
    consentStatusUnableToConsentText: {
      main: '#FA6843',
    },
    consentStatusRequiresReConsentText: {
      main: '#D69A04',
    },
    consentStatusFullConsentWithdrawnText: {
      main: '#F6F4FF',
    },
    consentStatusPartialConsentWithdrawnText: {
      main: '#F6F4FF',
    },
    consentStatusDeclinedText: {
      main: '#6E7191',
    },


    progressStatusTodoBackground: {
      main: '#FFE2DB',
    },
    progressStatusInProgressBackground: {
      main: '#FFDCBB',
    },
    progressStatusNotePreppedBackground: {
      main: '#FFF1BE',
    },
    progressStatusNotePreppedExpectingDocsBackground: {
      main: '#D5F7FB',
    },
    progressStatusNotePreppedReviewNeededBackground: {
      main: '#FEE1FF',
    },
    progressStatusSignedBackground: {
      main: '#CCF8DC',
    },
    progressStatusCompletedBackground: {
      main: '#CCF8DC',
    },
    progressStatusNoWorkNeededBackground: {
      main: '#F3F1F1',
    },
    progressStatusTodoText: {
      main: '#FA6843',
    },
    progressStatusInProgressText: {
      main: '#FF8F27',
    },
    progressStatusNotePreppedText: {
      main: '#D69A04',
    },
    progressStatusNotePreppedExpectingDocsText: {
      main: '#52A7B4',
    },
    progressStatusNotePreppedReviewNeededText: {
      main: '#E34CDD',
    },
    progressStatusSignedText: {
      main: '#229D4E',
    },
    progressStatusCompletedText: {
      main: '#229D4E',
    },
    progressStatusNoWorkNeededText: {
      main: '#6F6C7D',
    },

    mrnBackground: {
      main: '#D0EEFF'
    },
    priorityHighBackground: {
      main: '#FFD1D1',
    },
    priorityMediumBackground: {
      main: '#FFE8C6',
    },
    priorityLowBackground: {
      main: '#D5F7FB',
    },
    priorityHighText: {
      main: '#F26A6A',
    },
    priorityMediumText: {
      main: '#FD7D07',
    },
    priorityLowText: {
      main: '#0A5AD1',
    },
    abstractionStatusBackground: {
      main: '#CCF8DC',
    },
    abstractionStatusText: {
      main: '#229D4E',
    },
  },
});

export default theme;
