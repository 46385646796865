import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {useTheme} from "@mui/material/styles";

const tabs = [
  {
    label: 'Abstracted Data',
    name: 'AbstractedData',
  },
  {
    label: 'Consent',
    name: 'Consent',
  },
];

const PatientSummary: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} pl={4} pr={2}
           sx={{backgroundColor: theme.palette.secondary.main}}>
        <Box display={'flex'} alignItems={'flex-end'}>
          <Box pt={1.5} pb={1.5}>
            <Skeleton variant="rounded" width={100} height={32}/>
          </Box>
          <Box display={'flex'} ml={3} alignItems={'flex-end'}>
            {tabs.map(tab => (
              <Box key={tab.name} pt={1} pl={1} pr={1} pb={1} mr={0.5}
                   sx={{
                     backgroundColor: theme.palette.accentListTable.main,
                     borderTopLeftRadius: 6,
                     borderTopRightRadius: 6
                   }}>
                <Skeleton variant="rounded" width={100} height={32}/>
              </Box>
            ))}
          </Box>
        </Box>
        <Skeleton variant="circular" width={24} height={24}/>
      </Box>
      <Box pl={4} pt={4} pr={2}>
        <Box mb={2.5} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            <Skeleton variant="rounded" width={50} height={18}/>
            <Skeleton variant="rounded" width={50} height={18}/>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <Skeleton variant="rounded" width={50} height={18}/>
            <Skeleton variant="rounded" width={50} height={18}/>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <Skeleton variant="rounded" width={50} height={18}/>
            <Skeleton variant="rounded" width={50} height={18}/>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <Skeleton variant="rounded" width={50} height={18}/>
            <Skeleton variant="rounded" width={50} height={18}/>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PatientSummary;
