import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {Range} from 'react-date-range';
import Snackbar from '@mui/material/Snackbar';
import Alert, {AlertColor} from '@mui/material/Alert';
import moment from "moment";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";

import Header from "../../components/common/header/Header";
import DateRangeFilter from '../../components/common/dateRangeFilter/DateRangeFilter';
import MultiSelectFilter from "../../components/common/multiSelectFilter/MultiSelectFilter";
import Result, {PatientProps} from "../../components/patientSchedule/result/Result";
import AddTask from "../../components/patientSchedule/task/AddTask";
import {ClinicSiteProps, ProviderProps, useAppContext} from "../../context/AppContext";
import SearchBox from "../../components/common/searchBox/SearchBox";
import styles from "../tasks/Tasks.module.css";
import SearchPopup, {initialSearchValues, SearchValuesProps} from "../../components/common/search/SearchPopup";

export interface NotificationMessageProps {
  message: string;
  severity: AlertColor;
}

const PatientSchedule: React.FC = () => {
  const theme = useTheme();
  const {clinicalSites, scheduleProviders} = useAppContext();
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<Range>({
    key: 'selection',
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [selectedClinicalSites, setSelectedClinicalSites] = useState<ClinicSiteProps[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<ProviderProps[]>([]);
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessageProps | null>(null);
  const [showSearchPatient, setShowSearchPatient] = useState<boolean>(false);
  const [selectedPatients, setSelectedPatients] = useState<PatientProps[]>([]);
  const [searchParams, setFilterParams] = useState<SearchValuesProps>(initialSearchValues);

  const updateDateRange = (range: Range) => {
    if (range.startDate) {
      range.startDate = moment(range.startDate).startOf('day').toDate();
      range.endDate = moment(range.endDate).endOf('day').toDate();
    }
    setDateRange(range);
  };

  const updateClientSites = (selected: ClinicSiteProps[]) => {
    setSelectedClinicalSites(selected);
  };

  const updateProvider = (selected: ProviderProps[]) => {
    setSelectedProviders(selected);
  };

  const addNewTask = (patients: PatientProps[]) => {
    setSelectedPatients(patients);
    setShowTaskModal(true);
  }

  const hideTaskModal = () => setShowTaskModal(false);

  const removeNotificationMessage = () => setNotificationMessage(null);

  const onSearchContainerClick = () => setShowSearchPatient(true);

  const hideSearchModal = () => setShowSearchPatient(false);

  const updateSearchParams = (result: SearchValuesProps) => {
    setFilterParams(result);
  };

  return (
    <>
      <Header pageTitle={'Visit List'}/>
      <Box className={styles.pageContainer}>
        <Box display={'flex'} mt={2} ml={2} alignItems={'center'}>
          <SearchBox searchLabel={'Search Visits'} onSearchContainerClick={onSearchContainerClick}/>
          <Box display={'flex'} ml={2}>
            <DateRangeFilter updateDateRange={updateDateRange} dateRange={dateRange}/>
            <MultiSelectFilter selectedItemCount={selectedClinicalSites.length} options={clinicalSites}
                               label={'Site'}
                               updateFilters={updateClientSites} menuLabel={'site_name'} menuValue={'site_uuid'}/>
            <MultiSelectFilter selectedItemCount={selectedProviders.length} options={scheduleProviders}
                               label={'Scheduled Provider'}
                               updateFilters={updateProvider} menuLabel={'attending_doctor_name'}
                               menuValue={'attending_doctor_uuid'}/>
          </Box>
        </Box>
        <Box p={2}>
          {(searchParams.lastName.length > 0 || searchParams.firstName.length > 0 ||
            searchParams.mrnNo.length > 0 || searchParams.npmId.length > 0 ||
            selectedClinicalSites.length > 0 || selectedProviders.length > 0) && (
            <Box p={1} display={'flex'} flexWrap={'wrap'}
                 sx={{backgroundColor: theme.palette.accentListTable.main, borderRadius: 2}}>
              {searchParams.lastName.length > 0 &&
                <Typography variant={'h6'}><b>Last Name:</b> {searchParams.lastName}&nbsp;&nbsp;</Typography>}
              {searchParams.firstName.length > 0 &&
                <Typography variant={'h6'}><b>First Name:</b> {searchParams.firstName}&nbsp;&nbsp;</Typography>}
              {searchParams.mrnNo.length > 0 &&
                <Typography variant={'h6'}><b>Mrn:</b> {searchParams.mrnNo}&nbsp;&nbsp;</Typography>}
              {searchParams.npmId.length > 0 &&
                <Typography variant={'h6'}><b>NPM Id:</b> {searchParams.npmId}&nbsp;&nbsp;</Typography>}
              {selectedClinicalSites.length > 0 &&
                <Typography variant={'h6'}><b>Site:&nbsp;</b>
                  {selectedClinicalSites.map((one: ClinicSiteProps) => one.site_name).join(', ')}&nbsp;&nbsp;
                </Typography>}
              {selectedProviders.length > 0 &&
                <Typography variant={'h6'}><b>Schedule Provider:&nbsp;</b>
                  {selectedProviders.map((one: ProviderProps) => `${one.first_name} ${one.last_name}`).join(', ')}&nbsp;&nbsp;
                </Typography>}
            </Box>
          )}
          <Result addNewTask={addNewTask} clinicalSites={selectedClinicalSites}
                  dateRange={dateRange}
                  providers={selectedProviders} searchParams={searchParams}/>
        </Box>
      </Box>

      {showTaskModal && (
        <AddTask selectedPatients={selectedPatients} open={showTaskModal}
                 handleClose={hideTaskModal}
                 updateNotificationMessage={setNotificationMessage}/>
      )}
      {showSearchPatient && (
        <SearchPopup title={'Find Patient'} searchParams={searchParams} open={showSearchPatient}
                     handleClose={hideSearchModal}
                     updateSearchParams={updateSearchParams}/>
      )}
      <Snackbar open={!!notificationMessage} autoHideDuration={5000} onClose={removeNotificationMessage}>
        <Alert severity={notificationMessage?.severity || 'success'} sx={{width: '100%'}}>
          {notificationMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default PatientSchedule;
