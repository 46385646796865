import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {v4 as uuidv4} from 'uuid';
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";

import PrimaryButton from "../../../common/primaryButton/PrimaryButton";
import SecondaryButton from "../../../common/secondaryButton/SecondaryButton";
import {useAppContext} from "../../../../context/AppContext";
import {stringToColor} from "../../../../utils/helperFunction";
import {FormikHelpers} from "formik/dist/types";
import {APIS} from "../../../../utils/constants";
import {CommentProps} from "./Comments";
import useNetworkHandling from "../../../../hooks/useNetworkHandling";

interface AddCommentProps {
  taskId: string;
  addComment: (comment: CommentProps) => void;
}

interface CommentFormValues {
  comment: string;
}

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  comment: Yup.string().required('Comment is required'),
});

const initialValues: any = {
  comment: '',
};

const AddComment: React.FC<AddCommentProps> = ({taskId, addComment}) => {
  const {postData} = useNetworkHandling();
  const {user} = useAppContext();

  const handleSubmit = async (values: CommentFormValues, formikHelpers: FormikHelpers<CommentFormValues>) => {
    try {
      await postData(`${APIS.TASKS}/${taskId}/comments`, {
        comment: values.comment,
        task_id: taskId,
      });
      addComment({
        comment: values.comment,
        comment_time: new Date().toISOString(),
        id: uuidv4(),
        created_by: user.name
      });
      formikHelpers.resetForm({values: initialValues});
    } catch (e) {
      console.log(e);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Box mb={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({values, handleChange, setFieldValue, errors, touched, isSubmitting}) => {
          return (
            <Form>
              <Box display={'flex'} alignItems={'center'}>
                <Box mr={1}>
                  <Avatar alt={user?.name}
                          sx={{width: 32, height: 32, bgcolor: stringToColor(user?.name), marginRight: 1}}>
                    {user?.name[0].toUpperCase()}
                  </Avatar>
                </Box>
                <Field name="comment">
                  {({field}: { field: any }) => (
                    <TextField
                      {...field}
                      disabled={isSubmitting}
                      label="Add Comment"
                      variant="outlined"
                      name={'comment'}
                      multiline
                      fullWidth size={'small'}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('comment', event.target.value);
                      }}
                      error={Boolean(errors.comment) && Boolean(touched.comment)}
                      helperText={touched.comment && errors.comment}
                    />
                  )}
                </Field>
              </Box>
              <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mt={1}>
                <Box mr={1}>
                  <SecondaryButton type={'button'} size={'small'}>
                    Cancel
                  </SecondaryButton>
                </Box>
                <Box ml={1}>
                  <PrimaryButton type={'submit'} disabled={isSubmitting} size={'small'}
                                 endIcon={isSubmitting ? <CircularProgress size={14}/> : <SaveIcon/>}>
                    Save
                  </PrimaryButton>
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  );
};

export default AddComment;

