import React, {useEffect, useState} from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";

import {stringToColor} from "../../../../utils/helperFunction";
import {RoleProps, useAppContext, UserProps} from "../../../../context/AppContext";
import {APIS} from "../../../../utils/constants";
import CircularProgress from "@mui/material/CircularProgress";
import {TaskProps} from "../../../../pages/tasks/Tasks";
import useNetworkHandling from "../../../../hooks/useNetworkHandling";


interface Props {
  taskId: string;
  view: string;
  assignee: string;
  updateTask: (taskId: string, task: TaskProps) => void;
}

const Assignee: React.FC<Props> = ({view, taskId, assignee, updateTask}) => {
  const {postData} = useNetworkHandling();
  const theme = useTheme();
  const {users, roles} = useAppContext();
  const [taskUsers, setTaskUsers] = useState<UserProps[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserProps | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [apiInProgress, setApiInProgress] = useState<boolean>(false);

  // Function to handle opening the menu
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAssigneeChange = async (newAssignee: string) => {
    try {
      setApiInProgress(true);
      handleMenuClose();
      const {data} = await postData(`${APIS.TASKS}/${taskId}/vpp/${newAssignee}`, null);
      updateTask(taskId, data);
    } catch (e) {
      console.log(e);
    } finally {
      setApiInProgress(false);
    }
  };

  useEffect(() => {
    if (assignee) {
      const assignedUser = users.find((user: UserProps) => user.id === assignee);
      if (assignedUser) {
        setSelectedUser(assignedUser);
      }
    }
  }, [assignee]);

  useEffect(() => {
    if (view) {
      const role = roles.find((role: RoleProps) => role.name === view);
      if (role) {
        setTaskUsers(users.filter((user: UserProps) => user.vpp_role_id === role.id));
      } else {
        setTaskUsers([]);
      }
    } else {
      setTaskUsers([]);
    }
  }, [view, users]);

  if (selectedUser) {
    return (
      <>
        <Box sx={{pointerEvents: apiInProgress ? 'none' : 'inherit'}} onClick={handleMenuOpen} display={'flex'}
             alignItems={'center'}>
          {apiInProgress ? (
            <CircularProgress size={20} sx={{marginRight: 1}}/>
          ) : (
            <Avatar alt={selectedUser?.name}
                    sx={{width: 24, height: 24, bgcolor: stringToColor(selectedUser?.name), marginRight: 1}}>
              {selectedUser?.name[0].toUpperCase()}
            </Avatar>
          )}
          <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
            {selectedUser?.name}
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}>
          {users.map((user: UserProps) => (
            <MenuItem key={user.id} onClick={() => handleAssigneeChange(user.id)}>
              <Avatar alt={user.name}
                      sx={{width: 24, height: 24, bgcolor: stringToColor(user.name), marginRight: 1}}>
                {user.name[0].toUpperCase()}
              </Avatar>
              <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
                {user.name}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  } else {
    const name = 'Unassigned';
    return (
      <>
        <Box sx={{pointerEvents: apiInProgress ? 'none' : 'inherit'}} onClick={handleMenuOpen} display={'flex'}
             alignItems={'center'}>
          {apiInProgress ? (
            <CircularProgress size={20} sx={{marginRight: 1}}/>
          ) : (
            <Avatar alt={name}
                    sx={{width: 24, height: 24, bgcolor: stringToColor(name), marginRight: 1}}>
              U
            </Avatar>
          )}
          <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
            {name}
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}>
          {users.map((user: UserProps) => (
            <MenuItem key={user.id} onClick={() => handleAssigneeChange(user.id)}>
              <Avatar alt={user.name}
                      sx={{width: 24, height: 24, bgcolor: stringToColor(user.name), marginRight: 1}}>
                {user.name[0].toUpperCase()}
              </Avatar>
              <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
                {user.name}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }
};

export default Assignee;
