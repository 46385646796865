import React, {useState} from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";

import {APIS, DHS_TASK_PROGRESS_STATUS, VPP_TASK_PROGRESS_STATUS} from "../../../../utils/constants";
import {TaskProps} from "../../../../pages/tasks/Tasks";
import useTaskStatusColor from "../../../../hooks/useTaskStatusColor";
import useNetworkHandling from "../../../../hooks/useNetworkHandling";

interface Props {
  view: string;
  status: string;
  taskId: string;
  updateTask: (taskId: string, task: TaskProps) => void;
}

const ProgressStatus: React.FC<Props> = ({view, taskId, status, updateTask}) => {
  const {postData} = useNetworkHandling();
  const {applyTaskStatusColor} = useTaskStatusColor();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [apiInProgress, setApiInProgress] = useState<boolean>(false);

  // Function to handle opening the menu
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProgressMenu = async (newStatus: string) => {
    try {
      handleMenuClose();
      setApiInProgress(true);
      handleMenuClose();
      const {data} = await postData(`${APIS.TASKS}/${taskId}/status`, {status: newStatus});
      updateTask(taskId, data);
    } catch (e) {
      console.log(e);
    } finally {
      setApiInProgress(false);
    }
  };

  const availableStatusList = view === 'vpp' ? VPP_TASK_PROGRESS_STATUS : DHS_TASK_PROGRESS_STATUS;
  return (
    <>
      <Button
        disabled={apiInProgress} onClick={handleMenuOpen} size={'small'} variant={'contained'}
        sx={applyTaskStatusColor(status)}>
        {apiInProgress ? <CircularProgress size={20}/> : status}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}>
        {availableStatusList.filter(one => one !== status).map(item => (
          <MenuItem key={item} onClick={() => handleProgressMenu(item)}>
            <Box p={1} display={'flex'} justifyContent={'center'} sx={applyTaskStatusColor(item)}>
              {item}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
};

export default ProgressStatus;
