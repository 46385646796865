import React from 'react';
import Dialog from '@mui/material/Dialog';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import DialogContent from '@mui/material/DialogContent';
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import {FormikHelpers} from "formik/dist/types";

import PrimaryButton from "../../common/primaryButton/PrimaryButton";
import SecondaryButton from "../../common/secondaryButton/SecondaryButton";
import {useAppContext, UserProps} from "../../../context/AppContext";
import {APIS} from "../../../utils/constants";
import {NotificationMessageProps} from "../../../pages/patientSchedule/PatientSchedule";
import useNetworkHandling from "../../../hooks/useNetworkHandling";

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  assignTo: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
  }).nullable().required('Assign to is required'),
});

export interface AssignTaskFormValues {
  assignTo: UserProps | null;
}

export interface AssignTaskProps {
  selectedTasks: string[];
  open: boolean;
  handleClose: () => void;
  updateNotificationMessage: (message: NotificationMessageProps) => void;
  refreshTasks: () => void;
}

const initialValues: AssignTaskFormValues = {
  assignTo: null,
};


const AssignTasks: React.FC<AssignTaskProps> = ({
                                                  selectedTasks,
                                                  open,
                                                  handleClose,
                                                  updateNotificationMessage,
                                                  refreshTasks
                                                }) => {
  const {postData} = useNetworkHandling();
  const theme = useTheme();
  const {users, user} = useAppContext();

  // Function to handle form submission
  const handleSubmit = async (values: AssignTaskFormValues, formikHelpers: FormikHelpers<AssignTaskFormValues>) => {
    try {
      await postData(`${APIS.TASKS}/bulk/vpp`, selectedTasks.map((task: string) => {
        return {task_id: task, vpp_id: values.assignTo?.id}
      }));
      updateNotificationMessage({
        message: 'Task assigned successfully',
        severity: 'success'
      });
      handleClose();
      refreshTasks();
    } catch (e) {
      updateNotificationMessage({
        message: 'Something went wrong, please try again',
        severity: 'error'
      });
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
          <Typography variant={'h2'} color={theme.palette.hyperLink.main}>
            Assign Tasks
          </Typography>
          <IconButton aria-label="Close Modal" color="primary" onClick={handleClose} size={'small'}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({values, handleChange, setFieldValue, errors, touched, isSubmitting}) => (
            <Form>
              <Box>
                <Typography variant={'h6'} color={theme.palette.hyperLink.main}>
                  <strong>{selectedTasks.length}</strong> tasks are selected.
                </Typography>
                <Box mt={2} display={'flex'}>
                  <Typography variant={'h5'} color={theme.palette.blackBold.main}>
                    Task assigned by:&nbsp;
                  </Typography>
                  <Typography variant={'h6'} color={theme.palette.blackRegular.main}>
                    {user.name}
                  </Typography>
                </Box>
              </Box>

              <Box mt={2}>
                <Autocomplete
                  disabled={isSubmitting}
                  fullWidth
                  size={'small'}
                  id="assignTo"
                  getOptionLabel={(option: UserProps) => option.name}
                  value={values.assignTo}
                  onChange={(event, newValue) => {
                    setFieldValue('assignTo', newValue);
                  }}
                  options={users}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Assign To"
                      error={!!errors.assignTo && touched.assignTo}
                      helperText={touched.assignTo && errors.assignTo}
                    />
                  )}
                />
              </Box>

              <Box mt={2}>
                <Divider/>
              </Box>
              <Box justifyContent={'flex-end'} display={'flex'} mt={2} mb={1}>
                <Box mr={1}>
                  <SecondaryButton disabled={isSubmitting} type={'button'} size={'small'} onClick={handleClose}>
                    Cancel
                  </SecondaryButton>
                </Box>

                <PrimaryButton disabled={isSubmitting} type={'submit'} size={'small'}
                               endIcon={isSubmitting ? <CircularProgress size={14}/> : <SendIcon/>}>
                  Assign Task
                </PrimaryButton>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
};

export default AssignTasks;
