import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Range} from "react-date-range";
import moment from "moment/moment";

import MultiSelectFilter from "../../../common/multiSelectFilter/MultiSelectFilter";
import {CategoryProps, ClinicSiteProps, ProviderProps, useAppContext, UserProps} from "../../../../context/AppContext";
import DateRangeFilter from "../../../common/dateRangeFilter/DateRangeFilter";
import {PRIORITY_LIST, TASK_STATUS_LIST} from "../../../../utils/constants";
import MultiSelectFilterStrings from "../../../common/multiSelectFilter/MultiSelectFilterStrings";

interface Props {
  applyFilter: (filters: any) => void;
}

export interface FilterValuesProps {
  selectedClinicalSites: ClinicSiteProps[];
  selectedProviders: ProviderProps[];
  selectedCategories: CategoryProps[];
  selectedStatus: any[];
  selectedPriorities: any[];
  selectedUsers: UserProps[];
  dateRange: Range;
}

const initialDateRange = {
  key: 'selection',
  startDate: moment().startOf('day').toDate(),
  endDate: moment().endOf('day').toDate(),
};

export const initialFilterValues: FilterValuesProps = {
  selectedClinicalSites: [],
  selectedProviders: [],
  selectedCategories: [],
  selectedStatus: [],
  selectedPriorities: [],
  selectedUsers: [],
  dateRange: initialDateRange,
}

const SearchHeader: React.FC<Props> = ({applyFilter}) => {
  const {categories, clinicalSites, users, taskAttendingDoctors} = useAppContext();
  const [selectedClinicalSites, setSelectedClinicalSites] = useState<ClinicSiteProps[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<CategoryProps[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<any[]>([]);
  const [selectedPriorities, setSelectedPriorities] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserProps[]>([]);
  const [dateRange, setDateRange] = useState<Range>(initialDateRange);

  const updateDateRange = (range: Range) => {
    if (range.startDate) {
      range.startDate = moment(range.startDate).startOf('day').toDate();
      range.endDate = moment(range.endDate).endOf('day').toDate();
    }
    setDateRange(range);
  };

  const updateUsers = (selected: UserProps[]) => {
    setSelectedUsers(selected);
  };

  const updateCategories = (selected: CategoryProps[]) => {
    setSelectedCategories(selected);
  };

  const updateClientSites = (selected: ClinicSiteProps[]) => {
    setSelectedClinicalSites(selected);
  };

  const updateStatuses = (selected: any[]) => {
    setSelectedStatus(selected);
  };

  const updatePriorities = (selected: any[]) => {
    setSelectedPriorities(selected);
  };

  const updateProvider = (selected: string[]) => {
    setSelectedProviders(selected);
  };

  useEffect(() => {
    applyFilter({
      selectedClinicalSites, selectedProviders, selectedCategories, selectedStatus, selectedPriorities, selectedUsers,
      dateRange
    });
  }, [selectedClinicalSites, selectedProviders, selectedCategories, selectedStatus, selectedPriorities, selectedUsers, dateRange]);

  return (
    <Box display={'flex'} alignItems={'center'} pl={2} pr={2}>
      <DateRangeFilter updateDateRange={updateDateRange} dateRange={dateRange}/>
      <MultiSelectFilter selectedItemCount={selectedUsers.length} options={users} label={'Assignee'}
                         updateFilters={updateUsers} menuLabel={'name'} menuValue={'id'}/>
      <MultiSelectFilter selectedItemCount={selectedCategories.length} options={categories} label={'Task Category'}
                         updateFilters={updateCategories} menuLabel={'name'} menuValue={'id'}/>
      <MultiSelectFilter selectedItemCount={selectedStatus.length} options={TASK_STATUS_LIST} label={'Status'}
                         updateFilters={updateStatuses} menuLabel={'label'} menuValue={'name'}/>
      <MultiSelectFilter selectedItemCount={selectedClinicalSites.length} options={clinicalSites} label={'Site'}
                         updateFilters={updateClientSites} menuLabel={'site_name'} menuValue={'site_uuid'}/>
      <MultiSelectFilterStrings selectedItemCount={selectedProviders.length} options={taskAttendingDoctors} label={'Scheduled Provider'}
                         updateFilters={updateProvider} />
      <MultiSelectFilter selectedItemCount={selectedPriorities.length} options={PRIORITY_LIST} label={'Priority'}
                         updateFilters={updatePriorities} menuLabel={'label'}
                         menuValue={'name'}/>
    </Box>
  )
};

export default SearchHeader;
