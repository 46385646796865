import React, {useEffect, useState} from "react";
import {DateRangePicker, Range} from 'react-date-range';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import moment from 'moment';

import SecondaryButton from '../secondaryButton/SecondaryButton';
import PrimaryButton from '../primaryButton/PrimaryButton';
import styles from './DateRangeFilter.module.css';

interface Props {
  updateDateRange: (params: Range) => void;
  dateRange: Range;
}

/**
 * Component is used to render the date range picker, on date range button menu will open to accept the date range from user
 * @param updateDateRange: Function to update the date range
 * @param dateRange: preselected date range
 * @constructor
 */
const DateRangeFilter: React.FC<Props> = ({updateDateRange, dateRange}) => {
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
    key: 'selection',
  });
  const [showDialog, setShowDialog] = useState(false);

  const handleSelect = (ranges: any) => {
    setSelectionRange(ranges.selection);
  };

  const hideSelection = () => {
    setShowDialog(false);
  }
  const applySelection = () => {
    updateDateRange(selectionRange);
    hideSelection();
  }

  useEffect(() => {
    if (dateRange?.startDate) {
      setSelectionRange(dateRange);
    }
  }, [dateRange]);

  return (
    <>
      <Box className={styles.dateFilter}>
        {dateRange.startDate ? (
          <PrimaryButton size={'small'} onClick={() => setShowDialog(true)}>
            {moment(dateRange?.startDate).format('MMM DD, YYYY')} - {moment(dateRange?.endDate).format('MMM DD, YYYY')}
          </PrimaryButton>
        ) : (
          <SecondaryButton size={'small'} onClick={() => setShowDialog(true)}>
            Start Date - End Date
          </SecondaryButton>
        )}
      </Box>
      <Dialog open={showDialog} maxWidth={'lg'}>
        <DialogContent>
          <DateRangePicker
            minDate={new Date()}
            months={2}
            direction="horizontal"
            scroll={{enabled: false}}
            ranges={[selectionRange]}
            onChange={handleSelect}
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton size={'small'} onClick={hideSelection}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size={'small'} onClick={applySelection}>
            Apply
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );

};

export default DateRangeFilter;
