import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import {useTheme} from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

import styles from './MultiSelectFilter.module.css';
import SecondaryButton from '../secondaryButton/SecondaryButton';
import PrimaryButton from "../primaryButton/PrimaryButton";

interface MultiSelectProps {
  options: any[];
  label: string;
  updateFilters: (selected: any[]) => void;
  menuLabel: string;
  menuValue: string;
  selectedItemCount: number | undefined;
}

/**
 * Component is used to render generic multiselect dropdown,
 * @param Icon: Icon to be shown on the button at the end
 * @param options: List of options for the selection
 * @param label: Label on the button
 * @param menuLabel: label of menu option
 * @param menuValue: value of menu option
 * @param updateFilters: callback function when the filter selection is completed
 * @constructor
 */
const MultiSelectFilter: React.FC<MultiSelectProps> = ({
                                                         options,
                                                         label,
                                                         updateFilters,
                                                         menuLabel,
                                                         menuValue,
                                                         selectedItemCount = 0
                                                       }) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options);

  // Function to handle opening the menu
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: any, item: any) => {
    if (event.target.checked) {
      setSelectedOptions(selectedOptions.concat([item]));
    } else {
      setSelectedOptions(selectedOptions.filter(option => option[menuValue] !== event.target.value));
    }
  };

  const selectAllOptions = () => {
    setSelectedOptions(options);
  };

  const clearAllOptions = () => {
    setSelectedOptions([]);
  };

  const applySelection = () => {
    updateFilters(selectedOptions);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (inputValue) {
      const filteredRecords = options.filter(record =>
        record[menuLabel].toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredOptions(filteredRecords);
    } else {
      setFilteredOptions([...options]);
    }
  }, [inputValue, options]);

  return (
    <Box className={styles.multiSelectContainer}>
      {selectedItemCount > 0 ? (
        <PrimaryButton size={'small'} onClick={handleMenuOpen}>
          {label}
        </PrimaryButton>
      ) : (
        <SecondaryButton onClick={handleMenuOpen} size={'small'}>
          {label}
        </SecondaryButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}>
        <Box className={styles.menuContainer}>
          <Box className={styles.menuItem}>
            <Typography variant={'body2'} sx={{color: theme.palette.hyperLink.main, marginRight: 1.5}}
                        className={styles.button} onClick={selectAllOptions}>
              Select all
            </Typography>
            <Typography variant={'body2'} sx={{color: theme.palette.hyperLink.main}} className={styles.button}
                        onClick={clearAllOptions}>
              Clear
            </Typography>
          </Box>
          <Box className={styles.menuItem}>
            <TextField size={'small'} variant={'outlined'} label={'Search'} value={inputValue}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                         setInputValue(event.target.value);
                       }}/>
          </Box>
          <Box className={styles.menuList}>
            {filteredOptions.map(item => (
              <Box key={item[menuValue]} className={styles.menuItem}>
                <FormControlLabel
                  value={item[menuValue]}
                  checked={selectedOptions.findIndex(one => one[menuValue] === item[menuValue]) > -1}
                  onChange={(event) => handleChange(event, item)}
                  control={<Checkbox sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 1.5,
                    '& .MuiSvgIcon-root': {fontSize: 18}
                  }}
                  />}
                  label={<Typography variant={'body1'}
                                     sx={{color: theme.palette.blackRegular.main}}>{item[menuLabel]}</Typography>}/>
              </Box>
            ))}
          </Box>
          <Box className={styles.actionContainer}>
            <SecondaryButton size={'small'} onClick={handleMenuClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size={'small'} onClick={applySelection}>
              Apply
            </PrimaryButton>
          </Box>
        </Box>
      </Menu>
    </Box>
  )
};

export default MultiSelectFilter;
