import React, {createContext, useContext, useState} from 'react';
import {UserClaims} from "@okta/okta-auth-js";

interface Props {
  children: any;
}

export interface CategoryProps {
  id: string;
  name: string;
  type: string;
}

export interface RoleProps {
  id: string;
  name: string;
}

export interface ClinicSiteProps {
  site_uuid: string;
  site_name: string;
}

export interface UserProps {
  id: string;
  name: string;
  vpp_role_id: string;
}

export interface ProviderProps {
  attending_doctor_uuid: string;
  first_name: string;
  last_name: string;
  attending_doctor_name: string;
}

export interface PriorityProps {
  id: number;
  name: string;
}


const allPriorities = [
  {id: 0, name: 'High'},
  {id: 1, name: 'Medium'},
  {id: 2, name: 'Low'},
];

const AppContext: React.Context<any> = createContext(null);

export const AppWrapper: React.FC<Props> = ({children}) => {
  const [roles, setRoles] = useState<RoleProps[]>([]);
  const [categories, setCategories] = useState<CategoryProps[]>([]);
  const [clinicalSites, setClinicalSites] = useState<ClinicSiteProps[]>([]);
  const [scheduleProviders, setScheduleProviders] = useState<ProviderProps[]>([]);
  const [taskAttendingDoctors, setTaskAttendingDoctors] = useState<string[]>([]);
  const [user, setUser] = useState<UserClaims | null>(null);
  const [users, setUsers] = useState<UserProps[]>([]);
  const [priorities, setPriorities] = useState<PriorityProps[]>(allPriorities);
  const [providersMap, setProvidersMap] = useState<any>({});
  const [sites, setSites] = useState<any>({});
  const [usersMap, setUsersMap] = useState<any>({});
  const [bearerToken, setBearerToken] = useState<string>('');

  return (
    <AppContext.Provider value={{
      roles,
      setRoles,
      categories,
      setCategories,
      clinicalSites,
      setSites,
      sites,
      setClinicalSites,
      user,
      setUser,
      users,
      setUsers,
      priorities,
      setPriorities,
      scheduleProviders,
      setScheduleProviders,
      providersMap,
      setProvidersMap,
      bearerToken,
      setBearerToken,
      usersMap,
      setUsersMap,
      taskAttendingDoctors,
      setTaskAttendingDoctors
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
}
