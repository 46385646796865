import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Avatar from '@mui/material/Avatar';
import Box from "@mui/material/Box";

import {getInitials, stringToColor} from "../../../utils/helperFunction";
import {useAppContext} from "../../../context/AppContext";

/**
 * Component is used to render the username, avatar and notification icon on the top right after login
 * @constructor
 */
const UserInfo = () => {
  const theme = useTheme();
  const {user} = useAppContext();

  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Box display={'flex'} alignItems={'center'}>
        <Box>
          <Typography variant={'h4'} color={theme.palette.blackBold.main}>
            {user?.name}
          </Typography>
        </Box>
        <Box ml={2}>
          <Avatar alt={user?.name} sx={{bgcolor: stringToColor(user?.name),  width: 32, height: 32}}>
            {getInitials(user?.name)}
          </Avatar>
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfo;
