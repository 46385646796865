import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import styles from "./Description.module.css";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from "@mui/icons-material/Send";
import {FormikHelpers} from "formik/dist/types";

import SecondaryButton from "../../../common/secondaryButton/SecondaryButton";
import PrimaryButton from "../../../common/primaryButton/PrimaryButton";
import {APIS} from "../../../../utils/constants";
import useNetworkHandling from "../../../../hooks/useNetworkHandling";

interface Props {
  description: string;
  taskId: string;
  updateTaskDescription: (description: string) => void;
}

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  description: Yup.string().required('Description is required'),
});

export interface DescriptionFormValues {
  description: string;
}


const Description: React.FC<Props> = ({description, taskId, updateTaskDescription}) => {
  const theme = useTheme();
  const {postData} = useNetworkHandling();
  const [editMode, setEditMode] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (values: DescriptionFormValues, formikHelpers: FormikHelpers<DescriptionFormValues>) => {
    try {
      await postData(`${APIS.TASKS}/${taskId}/description`, {
        task_id: taskId,
        description: values.description,
      });
      updateTaskDescription(values.description);
      setEditMode(false);
    } catch (e) {
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  if (editMode) {
    return (
      <Formik
        enableReinitialize
        initialValues={{description}}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({values, handleChange, setFieldValue, errors, touched, isSubmitting}) => {
          return (
            <Form>
              <Box mt={2}>
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  size={'small'}
                  name="description"
                  type="text"
                  label="Description"
                  value={values.description}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('description', event.target.value);
                  }}
                  error={Boolean(errors.description) && Boolean(touched.description)}
                  helperText={touched.description && errors.description}
                />
              </Box>
              <Box mt={2}>
                <Box>
                  <Divider/>
                </Box>
                <Box justifyContent={'flex-end'} display={'flex'} mt={2}>
                  <Box mr={1}>
                    <SecondaryButton disabled={isSubmitting} type={'button'} size={'small'}
                                     onClick={() => setEditMode(false)}>
                      Cancel
                    </SecondaryButton>
                  </Box>

                  <PrimaryButton disabled={isSubmitting} type={'submit'} size={'small'}
                                 endIcon={isSubmitting ? <CircularProgress size={14}/> : <SendIcon/>}>
                    Save
                  </PrimaryButton>
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    );
  }

  return (
    <>
      <Typography variant={'h5'} color={theme.palette.blackBold.main}>
        Description
      </Typography>
      <Box onClick={() => setEditMode(true)} className={styles.viewMode}>
        <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
          {description ?? 'Add description'}
        </Typography>
      </Box>
    </>
  )
};

export default Description;
