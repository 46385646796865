import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

interface Props {
  message: string;
}

/**
 * Component is used to render in the table if no records are available
 * @param message: message to be shown to the user
 * @constructor
 */
const NoDataMessage: React.FC<Props> = ({message}) => {
  const theme = useTheme();

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{minHeight: 80}}>
      <Typography variant={'h6'} color={theme.palette.blackRegular.main}>{message}</Typography>
    </Box>
  )
};

export default NoDataMessage;
