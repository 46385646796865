import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Logo from "../../assets/logo.svg";
import PrimaryButton from "../../components/common/primaryButton/PrimaryButton";
import {useTheme} from "@mui/material/styles";
import {Link} from "react-router-dom";
import {ROUTE_ACTIONS} from "../../utils/constants";

/**
 * Component is rendered when route is not present in the known route
 * @constructor
 */
const NotFound = () => {
  const theme = useTheme();

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}
         style={{backgroundColor: theme.palette.greyRegular.main}}>
      <Box>
        <Box mb={2}>
          <img src={Logo} alt={'N power logo'}/>
          <Typography variant={'h2'} style={{color: theme.palette.brandColorPrimary.main}}>
            Requested details not available
          </Typography>
        </Box>
        <Link to={ROUTE_ACTIONS.HOME}>
          <PrimaryButton>
            Navigate to Home
          </PrimaryButton>
        </Link>
      </Box>
    </Box>
  )
}

export default NotFound;
