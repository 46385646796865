import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';

export const REACT_APP_DOMAIN_NAME= 'npowermedicine.okta.com';
export const REACT_APP_CLIENT_ID= '0oa6pfe2pbqUKrfBQ697';

export const REACT_APP_SERVER_URL= 'https://cerberus.npowermedicine.com/';

/**
 * React app routes
 */
export const ROUTE_ACTIONS = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  TASKS: '/tasks',
  PATIENT_SCHEDULES: '/patient-schedules',
  ABSTRACTION_WORK_LIST: '/abstraction-work-list',
  LOGIN_CALLBACK: '/login/callback'
};

export const SIDE_MENU = [
  {
    icon: TaskOutlinedIcon,
    label: 'Task Queue',
    name: 'tasks',
    path: ROUTE_ACTIONS.TASKS,
  },
  {
    icon: EqualizerOutlinedIcon,
    label: 'Visit List',
    name: 'schedules',
    path: ROUTE_ACTIONS.PATIENT_SCHEDULES,
  },
];


export const VPP_TASK_PROGRESS_STATUS = [
  "To do",
  "In progress",
  "Done",
];

export const DHS_TASK_PROGRESS_STATUS = [
  "To do",
  "In progress",
  "Note Prepped",
  "Note Prepped - Expecting Docs",
  "Signed",
  "No Prep Needed",
];

export const APIS = {
  CATEGORIES: 'v1/api/categories',
  CLINIC_SITES: 'v1/api/clinic-sites',
  USERS: 'v1/api/vpp-users',
  ROLES: 'v1/api/vpp-roles',
  TASKS: 'v1/api/tasks',
  SELF_DETAILS: 'users/me',
  AUTHENTICATE: 'auth/access-token',
  PATIENTS: 'v1/api/patients',
  SCHEDULES: 'v1/api/schedules',
  SCHEDULE_PROVIDERS: 'v1/api/schedules/attending_doctor',
  TASKS_ATTENDING_DOCTOR: 'v1/api/schedules/task_attending_doctor',
  TASKS_PER_STATUS: 'v1/api/tasks_per_status'
}

export const TASK_STATUS = {
  To_do: "To do",
  In_progress: "In progress",
  Done: "Done",
  Note_Prepped: "Note Prepped",
  Note_Prepped_Expecting_Docs: "Note Prepped - Expecting Docs",
  No_Work_Needed: "No Work Needed",
  Signed: "Signed",
  No_Prep_Needed: "No Prep Needed",
};

export const TASK_STATUS_LIST = [
  {name: "To do", label: 'To do'},
  {name: "In progress", label: 'In progress'},
  {name: "Note Prepped", label: 'Note Prepped'},
  {name: "Note Prepped - Expecting Docs", label: 'Note Prepped - Expecting Docs'},
  {name: "Signed", label: 'Signed'},
  {name: "No Prep Needed", label: 'No Prep Needed'},
];

export const PRIORITY_LIST = [
  {name: 2, label: 'Low'},
  {name: 1, label: 'Medium'},
  {name: 0, label: 'High'},
];
