import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import moment from "moment";

import AddComment from "./AddComment";
import {stringToColor} from "../../../../utils/helperFunction";

export interface CommentProps {
  comment: string;
  comment_time: string;
  id: string;
  created_by: string;
}

interface Props {
  comments: CommentProps[];
  taskId: string;
  addComment: (comment: CommentProps) => void;
}

const Comments: React.FC<Props> = ({comments, taskId, addComment}) => {
  const theme = useTheme();

  return (
    <>
      <Box mb={1}>
        <Typography variant={'h4'} color={theme.palette.hyperLink.main}>
          Comments
        </Typography>
      </Box>
      <AddComment taskId={taskId} addComment={addComment}/>
      {comments.length > 0 && (
        <>
          <Divider/>
          {comments.map((comment) => (
            <Box key={comment.id}>
              <Box display={'flex'} alignItems={'flex-start'} key={comment.id} pb={1} pt={1}>
                <Box mr={1}>
                  <Avatar alt={comment?.created_by}
                          sx={{width: 32, height: 32, bgcolor: stringToColor(comment?.created_by), marginRight: 1}}>
                    {comment?.created_by[0].toUpperCase()}
                  </Avatar>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={0.5}>
                    <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
                      {comment?.created_by}
                    </Typography>
                    <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
                      {moment(comment.comment_time).fromNow()}
                    </Typography>
                  </Box>
                  <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
                    {comment.comment}
                  </Typography>
                </Box>
              </Box>
              <Divider/>
            </Box>
          ))}
        </>
      )}
    </>
  );
};

export default Comments;
