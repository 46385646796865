import {useTheme} from "@mui/material/styles";
import {TASK_STATUS} from "../utils/constants";

export default () => {
  const theme = useTheme();

  const applyTaskStatusColor = (taskStatus: string) => {
    const response: {
      bgcolor: string;
      color: string;
      borderRadius: number;
      fontWeight: string,
    } = {
      bgcolor: theme.palette.progressStatusTodoBackground.main,
      color: theme.palette.progressStatusTodoText.main,
      borderRadius: 2,
      fontWeight: '600',
    };
    switch (taskStatus) {
      case TASK_STATUS.To_do:
        response.bgcolor = theme.palette.progressStatusTodoBackground.main;
        response.color = theme.palette.progressStatusTodoText.main;
        break;

      case TASK_STATUS.In_progress:
        response.bgcolor = theme.palette.progressStatusInProgressBackground.main;
        response.color = theme.palette.progressStatusInProgressText.main;
        break;

      case TASK_STATUS.Done:
      case TASK_STATUS.Signed:
        response.bgcolor = theme.palette.progressStatusCompletedBackground.main;
        response.color = theme.palette.progressStatusCompletedText.main;
        break;

      case TASK_STATUS.Note_Prepped:
        response.bgcolor = theme.palette.progressStatusNotePreppedBackground.main;
        response.color = theme.palette.progressStatusNotePreppedText.main;
        break;

      case TASK_STATUS.Note_Prepped_Expecting_Docs:
        response.bgcolor = theme.palette.progressStatusNotePreppedExpectingDocsBackground.main;
        response.color = theme.palette.progressStatusNotePreppedExpectingDocsText.main;
        break;

      case TASK_STATUS.No_Work_Needed:
        response.bgcolor = theme.palette.progressStatusNoWorkNeededBackground.main;
        response.color = theme.palette.progressStatusNoWorkNeededText.main;
        break;
    }
    return response;
  };

  return {applyTaskStatusColor};
};
