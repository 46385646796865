import React from 'react';
import {DataGrid, GridCellParams} from '@mui/x-data-grid';
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";


const skeletonList = [
  {id: '0001', priority: '', title: '', description: '', dueDate: '', status: ''},
  {id: '0002', priority: '', title: '', description: '', dueDate: '', status: ''},
  {id: '0003', priority: '', title: '', description: '', dueDate: '', status: ''},
  {id: '0004', priority: '', title: '', description: '', dueDate: '', status: ''},
  {id: '0005', priority: '', title: '', description: '', dueDate: '', status: ''},
];

const columns = [
  {
    field: 'assignee',
    headerName: 'Assignee',
    width: 180,
    renderCell: (params: GridCellParams) => <Skeleton variant="rounded" width={150} height={15}/>,
  },
  {
    field: 'title',
    headerName: 'Task Category',
    width: 180,
    renderCell: (params: GridCellParams) => <Skeleton variant="rounded" width={150} height={15}/>,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params: GridCellParams) => <Skeleton variant="rounded" width={120} height={15}/>,
  },
  {
    field: 'due_date',
    headerName: 'Due',
    width: 180,
    renderCell: (params: GridCellParams) => <Skeleton variant="rounded" width={150} height={15}/>,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    width: 100,
    renderCell: (params: GridCellParams) => <Skeleton variant="rounded" width={80} height={15}/>,
  },
  {
    field: 'site',
    headerName: 'Site',
    width: 180,
    renderCell: (params: GridCellParams) => <Skeleton variant="rounded" width={150} height={15}/>,
  },
  {
    field: 'patient_mrn',
    headerName: 'MRN',
    width: 120,
    renderCell: (params: GridCellParams) => <Skeleton variant="rounded" width={100} height={15}/>,
  },
  {
    field: 'patient_npm_id',
    headerName: 'NPM ID',
    width: 100,
    renderCell: (params: GridCellParams) => <Skeleton variant="rounded" width={80} height={15}/>,
  },

  {
    field: 'patient_name',
    headerName: 'Patient Name',
    width: 180,
    renderCell: (params: GridCellParams) => <Skeleton variant="rounded" width={150} height={15}/>,
  },
  {
    field: 'provider',
    headerName: 'Scheduled Provider',
    width: 200,
    renderCell: (params: GridCellParams) => <Skeleton variant="rounded" width={150} height={15}/>,
  },
];

const TasksListSkeleton: React.FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{backgroundColor: theme.palette.accentListTable.main, borderRadius: 2}}>
      <DataGrid
        sx={{
          overflow: 'scroll', backgroundColor: theme.palette.accentListTable.main, "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            height: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            maxHeight: "168px !important",
            bgcolor: "#659AE94D"
          }
        }}
        density={'compact'}
        rows={skeletonList}
        columns={columns}
        checkboxSelection
        rowCount={skeletonList.length}
      />
    </Box>
  );
};

export default TasksListSkeleton;
