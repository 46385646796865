import React from 'react';
import Dialog from '@mui/material/Dialog';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Search';
import DialogContent from '@mui/material/DialogContent';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import DialogTitle from "@mui/material/DialogTitle";

import PrimaryButton from "../primaryButton/PrimaryButton";
import SecondaryButton from "../secondaryButton/SecondaryButton";

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  lastName: Yup.string().trim().max(30, 'Last name  can\'t exceed 30 characters.'),
  firstName: Yup.string().trim().max(30, 'Last name  can\'t exceed 30 characters.'),
  mrnNo: Yup.string().trim().max(30, 'Last name  can\'t exceed 30 characters.'),
  npmId: Yup.string().trim().max(30, 'Last name  can\'t exceed 30 characters.'),
});

export interface SearchValuesProps {
  lastName: string;
  firstName: string;
  mrnNo: string;
  npmId: string;
}

export interface SearchPopupProps {
  open: boolean;
  title: string;
  searchParams: SearchValuesProps;
  handleClose: () => void;
  updateSearchParams: (values: SearchValuesProps) => void;
}

export const initialSearchValues: SearchValuesProps = {
  lastName: '',
  firstName: '',
  mrnNo: '',
  npmId: '',
};


const SearchPopup: React.FC<SearchPopupProps> = ({searchParams, title, open, handleClose, updateSearchParams}) => {
  const theme = useTheme();

  // Function to handle form submission
  const handleSubmit = (values: SearchValuesProps) => {
    updateSearchParams(values);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle sx={{padding: 0}}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}
             sx={{bgcolor: theme.palette.hyperLink.main}} p={2}>
          <Box display={'flex'}>
            <Typography variant={'h2'} color={theme.palette.brandColorPrimary.main}>
              {title}
            </Typography>
          </Box>
          <Box>
            <IconButton aria-label="Close Modal" color="primary" onClick={handleClose} size={'small'}>
              <CloseIcon sx={{color: theme.palette.brandColorPrimary.main}}/>
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Formik
            enableReinitialize
            initialValues={searchParams}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({values, handleChange, setFieldValue, errors, touched, isSubmitting, resetForm}) => {
              return (
                <Box mt={2}>
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item sm={3}>
                        <TextField
                          disabled={isSubmitting}
                          fullWidth
                          size={'small'}
                          name="lastName"
                          value={values.lastName}
                          type="text"
                          label="Last Name"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('lastName', event.target.value);
                          }}
                          error={Boolean(errors.lastName) && Boolean(touched.lastName)}
                          helperText={touched.lastName && errors.lastName}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <TextField
                          disabled={isSubmitting}
                          fullWidth
                          size={'small'}
                          name="firstName"
                          value={values.firstName}
                          type="text"
                          label="First Name"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('firstName', event.target.value);
                          }}
                          error={Boolean(errors.firstName) && Boolean(touched.firstName)}
                          helperText={touched.firstName && errors.firstName}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <TextField
                          disabled={isSubmitting}
                          fullWidth
                          size={'small'}
                          name="mrnNo"
                          value={values.mrnNo}
                          type="text"
                          label="MRN #"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('mrnNo', event.target.value);
                          }}
                          error={Boolean(errors.mrnNo) && Boolean(touched.mrnNo)}
                          helperText={touched.mrnNo && errors.mrnNo}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <TextField
                          disabled={isSubmitting}
                          fullWidth
                          size={'small'}
                          name="npmId"
                          value={values.npmId}
                          type="text"
                          label="NPM ID"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('npmId', event.target.value);
                          }}
                          error={Boolean(errors.npmId) && Boolean(touched.npmId)}
                          helperText={touched.npmId && errors.npmId}
                        />
                      </Grid>
                      <Grid item sm={12}>
                        <Box>
                          <Divider/>
                        </Box>
                        <Box justifyContent={'flex-end'} display={'flex'} mt={2}>
                          <Box mr={1}>
                            <SecondaryButton disabled={isSubmitting} type={'button'} size={'small'}
                                             onClick={() => {
                                               resetForm({
                                                 values: initialSearchValues
                                               });
                                             }}>
                              Clear
                            </SecondaryButton>
                          </Box>

                          <PrimaryButton disabled={isSubmitting} type={'submit'} size={'small'}
                                         endIcon={isSubmitting ? <CircularProgress size={14}/> : <SendIcon/>}>
                            Search
                          </PrimaryButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                </Box>
              )
            }}
          </Formik>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  )
};

export default SearchPopup;
