import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import styles from "./TaskDetails.module.css";

const TaskSkeleton = () => {
  return (
    <Box pr={1} pl={2}>
      <Box display={'flex'} mb={2} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'}>
          <Box>
            <Skeleton variant="rounded" width={24} height={24}/>
          </Box>
          <Box ml={1}>
            <Skeleton variant="rounded" width={100} height={24}/>
          </Box>
          <Box ml={1}>
            <Skeleton variant="rounded" width={100} height={24}/>
          </Box>
        </Box>
        <Skeleton variant="circular" width={24} height={24}/>
      </Box>
      <Skeleton variant="rectangular" width={'100%'} height={32}/>
      <Box mt={2}>
        <Skeleton variant="rectangular" width={150} height={20}/>
        <Box mt={1}>
          <Skeleton variant="rectangular" width={'100%'} height={250}/>
        </Box>
      </Box>
      <Box mt={2} className={styles.details}>
        <Box p={1} className={styles.accordionTitle}>
          <Skeleton variant="rectangular" width={100} height={20}/>
        </Box>
        <Box display={'flex'} mt={1} p={1}>
          <Box flex={1}>
            <Skeleton variant="rectangular" width={100} height={20}/>
          </Box>
          <Box flex={1} display={'flex'} alignItems={'center'}>
            <Box mr={1}>
              <Skeleton variant={"circular"} width={24} height={24}/>
            </Box>
            <Skeleton variant="rectangular" width={150} height={20}/>
          </Box>
        </Box>
        <Box display={'flex'} p={1}>
          <Box flex={1}>
            <Skeleton variant="rectangular" width={100} height={20}/>
          </Box>
          <Box flex={1}>
            <Skeleton variant="rectangular" width={100} height={20}/>
          </Box>
        </Box>
        <Box display={'flex'} p={1}>
          <Box flex={1}>
            <Skeleton variant="rectangular" width={100} height={20}/>
          </Box>
          <Box flex={1}>
            <Skeleton variant="rectangular" width={100} height={20}/>
          </Box>
        </Box>
        <Box display={'flex'} p={1}>
          <Box flex={1}>
            <Skeleton variant="rectangular" width={100} height={20}/>
          </Box>
          <Box flex={1} display={'flex'} alignItems={'center'}>
            <Box mr={1}>
              <Skeleton variant={"circular"} width={24} height={24}/>
            </Box>
            <Skeleton variant="rectangular" width={100} height={20}/>
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default TaskSkeleton;
