import React, {useEffect, useRef, useState} from "react";
import {Navigate, Outlet} from 'react-router-dom';
import {useOktaAuth} from "@okta/okta-react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import SideDrawer from "../sideDrawer/SideDrawer";
import styles from './Layout.module.css';
import {APIS, ROUTE_ACTIONS} from "../../../utils/constants";
import {ClinicSiteProps, ProviderProps, useAppContext, UserProps} from "../../../context/AppContext";
import useNetworkHandling from "../../../hooks/useNetworkHandling";
import PrimaryButton from "../primaryButton/PrimaryButton";

/**
 * Component is used to render the layout the application
 * It will check if user is authenticated or not,
 * if authenticated, side drawer will be rendered on the left along with the header on the top
 * @constructor
 */
const Layout = () => {
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const oktaToken = useRef<string>('');
  const {getData, postData} = useNetworkHandling();
  const {oktaAuth, authState} = useOktaAuth();
  const {
    setCategories,
    setSites,
    setClinicalSites,
    setUser,
    setUsers,
    setScheduleProviders,
    setTaskAttendingDoctors,
    setRoles,
    setProvidersMap,
    bearerToken,
    setBearerToken,
    setUsersMap
  } = useAppContext();

  const getAllConstants = async () => {
    try {
      const [rolesResponse, usersResponse, categoriesResponse, scheduleProvidersResponse, clinicalSiteResponse, taskAttendingDoctorResponse] = await Promise.all([
        getData(APIS.ROLES),
        getData(APIS.USERS),
        getData(APIS.CATEGORIES),
        getData(APIS.SCHEDULE_PROVIDERS),
        getData(APIS.CLINIC_SITES),
        getData(APIS.TASKS_ATTENDING_DOCTOR),
      ]);
      setRoles(rolesResponse.data);
      setUsers(usersResponse.data);
      setCategories(categoriesResponse.data);
      setTaskAttendingDoctors(taskAttendingDoctorResponse.data);
      const providers: any = {};
      const sites: any = {};
      const usersMap: any = {};
      setScheduleProviders(scheduleProvidersResponse.data?.map((provider: ProviderProps) => {
        const attendingDoctorName = `${provider.first_name ?? ''} ${provider.last_name ?? ''}`;
        providers[provider.attending_doctor_uuid] = attendingDoctorName;
        return Object.assign({}, provider, {
          attending_doctor_name: attendingDoctorName,
        });
      }));
      setProvidersMap(providers);
      clinicalSiteResponse.data.forEach((one: ClinicSiteProps) => {
        sites[one.site_uuid] = one.site_name;
      });
      usersResponse.data.forEach((one: UserProps) => {
        usersMap[one.id] = one.name;
      });
      setSites(sites);
      setClinicalSites(clinicalSiteResponse.data);
      setUsersMap(usersMap);
    } catch (error) {
      console.log(error);
    }
  };

  const getSelfDetails = async () => {
    try {
      const userInfo = await oktaAuth.token.getUserInfo();
      const {data} = await getData(APIS.SELF_DETAILS);
      setUser(Object.assign({}, userInfo, {
        userId: data.id,
      }));
    } catch (e) {
      console.log(e);
    } finally {
      setShowLoader(false);
    }
  };

  const authenticateUser = async () => {
    try {
      setShowLoader(true);
      const {data} = await postData(APIS.AUTHENTICATE, null);
      setBearerToken(data.access_token);
    } catch (e) {
      setShowError(true);
      setShowLoader(false);
    }
  };

  const refreshClick = () => {
    window.location.href = ROUTE_ACTIONS.HOME;
  }

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const token = authState.accessToken?.accessToken || '';
      oktaToken.current = token;
      setBearerToken(token);
    }
  }, [authState]);

  useEffect(() => {
    if (bearerToken) {
      if (oktaToken.current === bearerToken) {
        authenticateUser().then(() => {
        });
      } else {
        getSelfDetails().then(r => {
        });
        getAllConstants().then(() => {
        });
      }
    }
  }, [bearerToken]);


  if (!authState) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress/>
      </Box>
    );
  }

  if (authState.isAuthenticated) {
    if (showLoader) {
      return (
        <Box height={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress/>
        </Box>
      )
    }

    if (showError) {
      return (
        <Box height={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Box alignItems={'center'} textAlign={'center'}>
            <Typography variant={'h6'}>
              Something went wrong please try again
            </Typography>
            <PrimaryButton onClick={refreshClick}>
              Refresh
            </PrimaryButton>
          </Box>
        </Box>
      )
    }
    return (
      <Box className={styles.appContainer}>
        <SideDrawer/>
        <Box className={styles.mainContainer}>
          <Outlet/>
        </Box>
      </Box>
    );
  }

  return (
    <Navigate to={ROUTE_ACTIONS.HOME}/>
  );
};

export default Layout;
