import React from 'react';
import Chip from "@mui/material/Chip";
import {useTheme} from "@mui/material/styles";

interface Props {
  taskPriority: number;
}

const Priority: React.FC<Props> = ({taskPriority}) => {
  const theme = useTheme();

  switch (taskPriority) {
    case 0:
      return <Chip variant="filled" size={'small'} label={'High'} sx={{
        bgcolor: theme.palette.priorityHighBackground.main,
        color: theme.palette.priorityHighText.main,
        padding: 1,
        borderRadius: 1,
        fontWeight: 'bold'
      }}/>;

    case 1:
      return <Chip variant="filled" size={'small'} label={'Medium'} sx={{
        bgcolor: theme.palette.priorityMediumBackground.main,
        color: theme.palette.priorityMediumText.main,
        padding: 1,
        borderRadius: 1,
        fontWeight: 'bold'
      }}/>;

    case 2:
      return <Chip variant="filled" size={'small'} label={'Low'} sx={{
        bgcolor: theme.palette.priorityLowBackground.main,
        color: theme.palette.priorityLowText.main,
        padding: 1,
        borderRadius: 1,
        fontWeight: 'bold'
      }}/>;

    default:
      return <Chip variant="filled" size={'small'} label={'Low'} sx={{
        bgcolor: theme.palette.priorityLowBackground.main,
        color: theme.palette.priorityLowText.main,
        padding: 1,
        borderRadius: 1,
        fontWeight: 'bold'
      }}/>;
  }
};

export default Priority;
