import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import React from "react";
import styles from "./SearchBox.module.css";

interface Props {
  onSearchContainerClick: () => void;
  searchLabel: string;
}

const SearchBox: React.FC<Props> = ({onSearchContainerClick, searchLabel}) => {
  return (
    <Box className={styles.searchContainer} onClick={onSearchContainerClick}>
      <TextField id="outlined-search" size="small" label={searchLabel} type="search" fullWidth
                 className={styles.inputContainer} />
    </Box>
  );
}

export default SearchBox;
