import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material/styles";
import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";

import TasksList from "../../components/tasks/list/TasksList";
import TaskDetails from "../../components/tasks/details/TaskDetails";
import styles from "./Tasks.module.css";
import TasksListSkeleton from "../../components/tasks/list/TasksListSkeleton";
import {APIS} from "../../utils/constants";
import SearchPopup, {initialSearchValues, SearchValuesProps} from "../../components/common/search/SearchPopup";
import useNetworkHandling from "../../hooks/useNetworkHandling";
import SearchHeader, {
  FilterValuesProps,
  initialFilterValues
} from "../../components/tasks/list/searchHeader/SearchHeader";
import {CategoryProps, ClinicSiteProps, UserProps} from "../../context/AppContext";
import SearchBox from "../../components/common/searchBox/SearchBox";
import useTaskStatusColor from "../../hooks/useTaskStatusColor";
import Header from "../../components/common/header/Header";

export interface TaskProps {
  title: string;
  description: string;
  due_date: string;
  patient_name: string;
  id: string;
  status: string;
  assignTo: string;
  createdBy: string;
  patient_mrn: string;
  patient_npm_id: string;
  clinicSite: string;
  scheduledProvider: string;
  comments: any[];
  task_view: string;
  priority: number;
  created_by: string;
  provider: string;
}

/**
 * Component is used to render tasks list page
 * @constructor
 */
const Tasks = () => {
  const {applyTaskStatusColor} = useTaskStatusColor();
  const firstTime = useRef(true);
  const {getData} = useNetworkHandling();
  const theme = useTheme();
  const [selectedTask, setSelectedTask] = useState<TaskProps | null>(null);
  const [rows, setRows] = useState<TaskProps[]>([]);
  const [stats, setStats] = useState<any[]>([]);
  const [apiRequested, setApiRequested] = useState<boolean>(false);
  const [showSearchTask, setShowSearchTask] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchValuesProps>(initialSearchValues);
  const [filtersParams, setFiltersParams] = useState<FilterValuesProps>(initialFilterValues);

  /**
   * Function is called when search bar is clicked
   */
  const onSearchContainerClick = () => setShowSearchTask(true);

  /**
   * Function is called to close the search modal open
   */
  const hideSearchModal = () => setShowSearchTask(false);

  /**
   * Get list of tasks
   * @param params
   * @param filters:
   */
  const getTasks = async (params: SearchValuesProps, filters: FilterValuesProps) => {
    try {
      setApiRequested(true);
      let url = `${APIS.TASKS}?due_date_start=${Math.floor(moment(filters.dateRange.startDate).valueOf() / 1000)}&due_date_end=${Math.floor(moment(filters.dateRange.endDate).valueOf() / 1000)}`;
      url = `${url}${params.npmId.length > 0 ? `&patient_npm=${params.npmId}` : ''}${params.mrnNo.length > 0 ? `&patient_mrn=${params.mrnNo}` : ''}`;
      url = `${url}${params.lastName.length > 0 ? `&patient_last_name=${params.lastName}` : ''}${params.firstName.length > 0 ? `&patient_first_name=${params.firstName}` : ''}`;
      url = `${url}${filters.selectedUsers.length > 0 ? `&assignees=${filters.selectedUsers.map((one: UserProps) => one.id).join(',')}` : ''}`;
      url = `${url}${filters.selectedCategories.length > 0 ? `&titles=${filters.selectedCategories.map((one: CategoryProps) => one.name).join(',')}` : ''}`;
      url = `${url}${filters.selectedStatus.length > 0 ? `&statuses=${filters.selectedStatus.map((one: any) => one.name).join(',')}` : ''}`;
      url = `${url}${filters.selectedClinicalSites.length > 0 ? `&sites=${filters.selectedClinicalSites.map((one: ClinicSiteProps) => one.site_uuid).join(',')}` : ''}`;
      url = `${url}${filters.selectedProviders.length > 0 ? `&providers=${filters.selectedProviders.join(';')}` : ''}`;
      url = `${url}${filters.selectedPriorities.length > 0 ? `&priority=${filters.selectedPriorities.map((one: any) => one.name).join(',')}` : ''}`;
      const {data} = await getData(encodeURI(url));
      setRows(data?.tasks);
    } catch (e) {
      console.log(e);
    } finally {
      setApiRequested(false);
    }
  };

  /**
   * Get tasks count per status
   */
  const getTaskPerCategory = async () => {
    try {
      const {data} = await getData(encodeURI(APIS.TASKS_PER_STATUS));
      setStats(data);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Function is used to update the task object, when any of the details changes
   * @param taskId: id of the task
   * @param newTask: updated task object containing new changes
   */
  const updateTaskDetails = (taskId: string, newTask: TaskProps) => {
    setRows(rows.map((one: TaskProps) => {
      if (one.id === taskId) {
        return newTask;
      }
      return one;
    }));
    setSelectedTask(newTask);
  };

  /**
   * Function is called when user clicks on the save button on search modal
   * @param result
   */
  const updateSearchParams = (result: SearchValuesProps) => {
    setSearchParams(result);
  };

  /**
   * Function is called when user clicks on the save button on search modal
   * @param result
   */
  const updateFilterParams = (result: FilterValuesProps) => {
    setFiltersParams(result);
  };

  const refreshTasks = () => {
    getTasks(searchParams, filtersParams).then(() => {
    });
  };

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;
    } else {
      getTasks(searchParams, filtersParams).then(() => {
      });
    }
  }, [searchParams, filtersParams]);

  useEffect(() => {
    getTaskPerCategory().then(() => {
    });
  }, []);

  return (
    <>
      <Header pageTitle={'Task Queue'}/>
      <Box className={styles.pageContainer}>
        {stats.length > 0 && (
          <Box mt={2} pl={2} mb={2} display={'flex'}>
            {stats.map(stat => (
              <Box key={stat.status} display={'flex'} pr={2}>
                <Box p={1} sx={applyTaskStatusColor(stat.status)}>
                  <Typography variant={'h6'} sx={{fontWeight: 'bold'}}>{stat.status}</Typography>
                </Box>
                <Box ml={0.5} p={1}
                     sx={{bgcolor: theme.palette.progressStatusNoWorkNeededBackground.main, borderRadius: 2}}>
                  <Typography variant={'h6'} color={theme.palette.blackBold.main}>{stat.count}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        <Box pl={2} pr={2} display={'flex'}>
          <SearchBox searchLabel={'Search Task'} onSearchContainerClick={onSearchContainerClick}/>
          <SearchHeader applyFilter={updateFilterParams}/>
        </Box>
        <Grid container spacing={0}>
          <Grid
            item xs={selectedTask ? 8 : 12}
            style={{
              transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}>
            <Box p={2} className={styles.tableContainer}>
              {(searchParams.mrnNo.length > 0 || searchParams.npmId.length > 0 ||
                searchParams.firstName.length > 0 || searchParams.lastName.length > 0 ||
                filtersParams.selectedUsers.length > 0 ||
                filtersParams.selectedCategories.length > 0 || filtersParams.selectedStatus.length > 0 ||
                filtersParams.selectedClinicalSites.length > 0 || filtersParams.selectedProviders.length > 0) && (
                <Box p={1} display={'flex'} flexWrap={'wrap'}
                     sx={{backgroundColor: theme.palette.accentListTable.main, borderRadius: 2}}>
                  {searchParams.mrnNo.length > 0 &&
                    <Typography variant={'h6'}><b>Mrn:</b> {searchParams.mrnNo}&nbsp;&nbsp;</Typography>}
                  {searchParams.npmId.length > 0 &&
                    <Typography variant={'h6'}><b>NPM Id:</b> {searchParams.npmId}&nbsp;&nbsp;</Typography>}
                  {searchParams.firstName.length > 0 &&
                    <Typography variant={'h6'}><b>First Name:</b> {searchParams.firstName}&nbsp;&nbsp;</Typography>}
                  {searchParams.lastName.length > 0 &&
                    <Typography variant={'h6'}><b>Last Name:</b> {searchParams.lastName}&nbsp;&nbsp;</Typography>}
                  {filtersParams.selectedUsers.length > 0 &&
                    <Typography variant={'h6'}>
                      <b>Assignee:&nbsp;</b>
                      {filtersParams.selectedUsers.map((one: UserProps) => one.name).join(', ')}&nbsp;&nbsp;
                    </Typography>
                  }
                  {filtersParams.selectedCategories.length > 0 &&
                    <Typography variant={'h6'}>
                      <b>Task Categories:&nbsp;</b>
                      {filtersParams.selectedCategories.map((one: CategoryProps) => one.name).join(', ')}&nbsp;&nbsp;
                    </Typography>
                  }
                  {filtersParams.selectedStatus.length > 0 &&
                    <Typography variant={'h6'}>
                      <b>Status:&nbsp;</b>
                      {filtersParams.selectedStatus.map((one: any) => one.label).join(', ')}&nbsp;&nbsp;
                    </Typography>
                  }
                  {filtersParams.selectedClinicalSites.length > 0 &&
                    <Typography variant={'h6'}>
                      <b>Site:&nbsp;</b>
                      {filtersParams.selectedClinicalSites.map((one: ClinicSiteProps) => one.site_name).join(', ')}&nbsp;&nbsp;
                    </Typography>
                  }
                  {filtersParams.selectedProviders.length > 0 &&
                    <Typography variant={'h6'}>
                      <b>Scheduled Provider:&nbsp;</b>
                      {filtersParams.selectedProviders.join(', ')}&nbsp;&nbsp;
                    </Typography>
                  }
                </Box>
              )}
              {apiRequested ? <TasksListSkeleton/> : (
                <TasksList setSelectedTask={setSelectedTask} rows={rows} refreshTasks={refreshTasks}/>
              )}
            </Box>
          </Grid>
          {!!selectedTask && (
            <Grid
              item xs={selectedTask ? 4 : 0}
              style={{
                transition: theme.transitions.create("all", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen
                })
              }}>
              <Box p={1}>
                <TaskDetails task={selectedTask} updateTask={updateTaskDetails}
                             clearSelectedTask={() => setSelectedTask(null)}/>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      {showSearchTask && (
        <SearchPopup title={'Search Task'} searchParams={searchParams} open={showSearchTask}
                     handleClose={hideSearchModal}
                     updateSearchParams={updateSearchParams}/>
      )}
    </>
  )
};

export default Tasks;
