import React from 'react';
import Button, {ButtonProps} from '@mui/material/Button';
import styles from './SecondaryButton.module.css';

/**
 * Component is used to render the outlined button
 * @param props
 * @constructor
 */
const SecondaryButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button {...props} variant="outlined" className={styles.secondaryButton}>
      {props.children}
    </Button>
  );
};

export default SecondaryButton;
