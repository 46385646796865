import React from 'react';
import {LoginCallback, Security} from '@okta/okta-react';
import OktaAuth, {toRelativeUrl} from '@okta/okta-auth-js';
import {ThemeProvider} from '@mui/material/styles';
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";

import theme from './theme/theme';
import Layout from "./components/common/layout/Layout";
import PatientSchedule from "./pages/patientSchedule/PatientSchedule";
import Home from "./pages/home/Home";
import NotFound from "./pages/notFound/NotFound";
import Tasks from "./pages/tasks/Tasks";
import {AppWrapper} from "./context/AppContext";
import {REACT_APP_DOMAIN_NAME, REACT_APP_CLIENT_ID, ROUTE_ACTIONS} from "./utils/constants";


const oktaAuth = new OktaAuth({
  issuer: `https://${REACT_APP_DOMAIN_NAME}/oauth2/default`,
  clientId: REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'email', 'profile', 'groups'],
});

/**
 * Main component of the application which will have all routes listed in the application
 * @constructor
 */
const AppWithRouterAccess = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {replace: true})
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path={ROUTE_ACTIONS.HOME} element={<Home/>}/>
        <Route path={ROUTE_ACTIONS.PATIENT_SCHEDULES} element={<Layout/>}>
          <Route
            index
            element={<PatientSchedule/>}
          />
        </Route>
        <Route path={ROUTE_ACTIONS.TASKS} element={<Layout/>}>
          <Route
            index
            element={<Tasks/>}
          />
        </Route>
        <Route path={ROUTE_ACTIONS.LOGIN_CALLBACK} element={<LoginCallback/>}/>
        <Route path={'*'} element={<NotFound/>}/>
      </Routes>
    </Security>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppWrapper>
          <AppWithRouterAccess/>
        </AppWrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
