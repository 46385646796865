import React, {useState} from 'react';
import {DataGrid, GridCellParams, GridValueGetterParams} from '@mui/x-data-grid';
import moment from "moment/moment";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Avatar from "@mui/material/Avatar";

import styles from "./TasksList.module.css";
import {TaskProps} from "../../../pages/tasks/Tasks";
import NoDataMessage from "../../common/noDataMessage/NoDataMessage";
import useTaskStatusColor from "../../../hooks/useTaskStatusColor";
import Priority from "../priority/Priority";
import {useAppContext} from "../../../context/AppContext";
import PrimaryButton from "../../common/primaryButton/PrimaryButton";
import AssignTasks from "../assignTasks/AssignTasks";
import {NotificationMessageProps} from "../../../pages/patientSchedule/PatientSchedule";
import {stringToColor} from "../../../utils/helperFunction";

interface Props {
  setSelectedTask: (task: TaskProps) => void;
  rows: TaskProps[];
  refreshTasks: () => void;
}

const getDueTime = (params: GridValueGetterParams) => {
  return moment(params.row.due_date).valueOf();
};

const TasksList: React.FC<Props> = ({
                                      setSelectedTask,
                                      rows,
                                      refreshTasks,
                                    }) => {
  const theme = useTheme();
  const {applyTaskStatusColor} = useTaskStatusColor();
  const {sites, usersMap} = useAppContext();
  const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false);
  const [tasksForAssignments, setTasksForAssignment] = useState<string[]>([]);
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessageProps | null>(null);

  const columns = [
    {
      field: 'assignTo',
      headerName: 'Assignee',
      width: 180,
      renderCell: (params: GridCellParams) => {
        let name = 'Unassigned';

        if (params?.row?.assignTo) {
          name = usersMap[params.row?.assignTo];
        }

        return (
          <Tooltip title={name}>
            <Box display={'flex'} alignItems={'center'}>
              {params?.row?.assignTo ? (
                <Avatar alt={name}
                        sx={{width: 24, height: 24, bgcolor: stringToColor(name), marginRight: 1}}>
                  {name ? name[0].toUpperCase() : <PermIdentityIcon/>}
                </Avatar>
              ) : (
                <Avatar alt={name}
                        sx={{width: 24, height: 24, bgcolor: theme.palette.disabledBackground.main, marginRight: 1}}>
                  <PermIdentityIcon/>
                </Avatar>
              )}
              <Typography variant={'h6'} color={theme.palette.blackBold.main} className={styles.singleLine}>
                {name}
              </Typography>
            </Box>
          </Tooltip>
        )
      }
    },
    {
      field: 'title',
      headerName: 'Task Category',
      width: 180,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.title}>
          <Typography variant={'h4'} color={theme.palette.blackBold.main} className={styles.singleLine}>
            {params.row.title}
          </Typography>
        </Tooltip>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params: GridCellParams) => {
        return params.row.status ? (
          <Chip variant="filled" size={'small'} label={params.row.status} sx={applyTaskStatusColor(params.row.status)}/>
        ) : <></>
      }
    },
    {
      field: 'due_date',
      headerName: 'Due',
      width: 180,
      valueGetter: getDueTime,
      renderCell: (params: GridCellParams) => {
        const dueDate = moment(params.row.due_date);
        return (
          <Typography variant={'body1'}
                      color={dueDate.isSame(new Date(), 'day') ? theme.palette.priorityHighText.main : theme.palette.blackRegular.main}>
            {dueDate.format('MM/DD/YYYY hh:mm A')}
          </Typography>
        )
      }
    },
    {
      field: 'priority',
      headerName: 'Priority',
      width: 100,
      renderCell: (params: GridCellParams) => {
        return <Priority taskPriority={params.row.priority}/>
      },
    },
    {
      field: 'site',
      headerName: 'Site',
      width: 180,
      valueGetter: (params: GridValueGetterParams) => sites[params.row.clinicSite],
      renderCell: (params: GridCellParams) => {
        const site = sites[params.row.clinicSite];
        return (
          <Tooltip title={site}>
            <Typography variant={'body1'} color={theme.palette.blackRegular.main} className={styles.singleLine}>
              {site}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: 'patient_mrn',
      headerName: 'MRN',
      width: 120,
      renderCell: (params: GridCellParams) => {
        const handleClick = (event: React.MouseEvent) => {
          event.stopPropagation();
          navigator.clipboard.writeText(params.row.patient_mrn);
        };

        return (
          <Tooltip
            title="Click to Copy"
          >
            <Chip variant="filled" size={'small'} label={params.row.patient_mrn} sx={{
              bgcolor: theme.palette.mrnBackground.main,
              color: theme.palette.blackRegular.main,
              padding: 1,
              borderRadius: 2,
              fontWeight: 'bold'
            }}
            onClick={(event) => handleClick(event)}/>
          </Tooltip>
        )
      }
    },
    {
      field: 'patient_npm_id',
      headerName: 'NPM ID',
      width: 100,
      renderCell: (params: GridCellParams) => (
        <Typography variant={'body1'} color={theme.palette.blackRegular.main}>
          {params.row.patient_npm_id}
        </Typography>
      )
    },
    {
      field: 'patient_name',
      headerName: 'Patient Name',
      width: 180,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.patient_name}>
          <Typography variant={'h6'} color={theme.palette.blackBold.main} className={styles.singleLine}>
            {params.row.patient_name}
          </Typography>
        </Tooltip>
      )
    },
    {
      field: 'provider',
      headerName: 'Scheduled Provider',
      width: 200,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.provider}>
          <Typography variant={'h6'} color={theme.palette.blackBold.main} className={styles.singleLine}>
            {params.row.provider}
          </Typography>
        </Tooltip>
      )
    },
  ];

  const handleTasksSelection = (newSelection: any) => setTasksForAssignment(newSelection);

  const removeNotificationMessage = () => setNotificationMessage(null);

  const closeModal = () => setShowAssignmentModal(false);

  const onAssignTasksClick = () => setShowAssignmentModal(true);

  return (
    <>
      <Box sx={{backgroundColor: theme.palette.accentListTable.main, borderRadius: 2}}>
        {tasksForAssignments.length > 0 && (
          <Box p={2}>
            <PrimaryButton size={'small'} onClick={onAssignTasksClick}>
              Assign Tasks
            </PrimaryButton>
          </Box>
        )}
        <DataGrid
          autoHeight
          disableRowSelectionOnClick
          sx={{
            overflow: 'scroll',
            backgroundColor: theme.palette.accentListTable.main,
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal"
            },
            "& .MuiDataGrid-columnHeader": {
              height: "unset !important"
            },
            "& .MuiDataGrid-columnHeaders": {
              maxHeight: "168px !important",
              bgcolor: "#659AE94D"
            }
          }}
          density={'compact'}
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: () => <NoDataMessage message={'No tasks available at the moment'}/>,
          }}
          initialState={{
            pagination: {
              paginationModel: {page: 0, pageSize: 10},
            },
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          onCellClick={(params) => {
            if (params.field !== '__check__') {
              setSelectedTask(params.row);
            }
          }}
          checkboxSelection
          rowCount={rows.length}
          onRowSelectionModelChange={handleTasksSelection}
        />
      </Box>
      <AssignTasks selectedTasks={tasksForAssignments} open={showAssignmentModal} handleClose={closeModal}
                   updateNotificationMessage={setNotificationMessage} refreshTasks={refreshTasks}/>
      <Snackbar open={!!notificationMessage} autoHideDuration={5000} onClose={removeNotificationMessage}>
        <Alert severity={notificationMessage?.severity || 'success'} sx={{width: '100%'}}>
          {notificationMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
}


export default TasksList;
